import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import qs from 'qs'
import {
  Button,
  ChartIcon,
  EntitiesList,
  GaugeIcon,
  IconButton,
  McatForChartIcon
} from 'examkrackers-components'
import { FetchExamsListPayload, Exam, PaginationProps, Course } from 'types'
import {
  getAccountType,
  getIsImpersonate,
  getStudent
} from 'modules/auth/ducks/selectors'
import SelectTimeOption from 'modules/exams/components/SelectTimeOption'
import ModalChangeAccessPeriod from 'modules/exams/components/ModalChangeAccessPeriod'
import ModalExamLogs from 'modules/exams/components/ModalExamLogs'
import ExamButton from 'modules/exams/components/ExamButton'
import { fetchExamsListRoutine } from 'modules/exams/ducks/actions'
import { getExamsList, getExamsPagination } from 'modules/exams/ducks/selectors'
import { studentFetchNavigationRoutine } from 'modules/student/ducks/actions'
import { getNavigation } from 'modules/student/ducks/selectors'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'

import { DEFAULT_ROWS_PER_PAGE, SORT_DIRECTION } from 'utils/table'
import {
  EXAM_STATUS,
  EXAM_TIME_OPTION_VALUES,
  SCORE_CALCULATION_METHOD
} from 'utils/exam'
import { DATE_FORMATS, formatDate, isDateBeforeToday } from 'utils/date'
import { COURSE_TYPES } from 'utils/courses'

import { isNilOrEmpty, isNotNilOrEmpty, mapIndexed } from 'utils/ramda'
import { isEmpty, pathOr } from 'ramda'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'

import RetakeOverallModal from 'modules/exams/components/RetakeOverallModal'

export const TableExams = (): JSX.Element => {
  const [refresh, setRefresh] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const studentDetails = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)
  const navigation = useSelector(getNavigation)
  const {
    location: { pathname, search },
    replace,
    push
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const examsPagination: PaginationProps = useSelector(getExamsPagination)
  const examsList: Exam[] = useSelector(getExamsList)

  const isImpersonate = useSelector(getIsImpersonate)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFreeTrialCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const fetchExams = (payload: FetchExamsListPayload) =>
    dispatch(fetchExamsListRoutine(payload))

  const fetchNavigation = () => dispatch(studentFetchNavigationRoutine())

  const findFirstSectionIdByExamId = (id: string) =>
    R.pipe(
      R.find(R.propEq('exam_id', id)),
      R.propOr([], 'sections'),
      R.head,
      R.propOr('', 'id')
    )(navigation)

  const handleRefresh = () => setRefresh(true)
  const getCalculatedMinutes = (minutes: number, id: string) => {
    const timeOptionElement = document.querySelectorAll(
      `[data-exam-time-option-id="${id}"]`
    )[0]
    const selectedTimeOption =
      timeOptionElement?.getAttribute('data-exam-time-option-value') || '1'
    return `${minutes * Number(selectedTimeOption)} ${t('exams.table.minutes')}`
  }

  const redirectToAnswerSheet =
    (id: string, sectionId: string | null, exam: Exam) => () => {
      const getPercentileRank = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'percentile_rank'])
      )(exam)

      const getRawScore = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'amount_correct'])
      )(exam)

      const getScaledScore = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'scaled_score'])
      )(exam)

      AnalyticsService(studentDetails).logEvent(
        ANALYTICS_EVENTS.answerSheetReviewed,
        {
          'Exam Title': R.propOr('', 'exam_title', exam),
          'Exam format': R.propOr('', 'type_title', exam),
          'Account type': accountType,
          'Precentile Rank': getPercentileRank,
          'Raw Score': getRawScore,
          'Scaled Score': getScaledScore,
          'Section Title': R.propOr('', 'title', exam),
          'Section Number': R.propOr('', 'section_order', exam)
        }
      )

      push(
        `/exam/${id}/score-report/answers-graph/${
          sectionId || findFirstSectionIdByExamId(id)
        }/answer-sheet`
      )
    }

  const redirectToDiagnostics =
    (id: string, sectionId: string | null, exam: Exam) => () => {
      const getPercentileRank = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'percentile_rank'])
      )(exam)

      const getRawScore = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'amount_correct'])
      )(exam)

      const getScaledScore = R.pipe(
        R.propOr('{}', 'scores'),
        JSON.parse,
        R.pathOr(0, ['exam', 'scaled_score'])
      )(exam)

      AnalyticsService(studentDetails).logEvent(
        ANALYTICS_EVENTS.diagnosticsReviewed,
        {
          'Exam Title': R.propOr('', 'exam_title', exam),
          'Exam format': R.propOr('', 'type_title', exam),
          'Account type': accountType,
          'Precentile Rank': getPercentileRank,
          'Raw Score': getRawScore,
          'Scaled Score': getScaledScore,
          'Section Title': R.propOr('', 'title', exam),
          'Section Number': R.propOr('', 'section_order', exam)
        }
      )

      push(
        `/exam/${id}/score-report/answers-graph/${
          sectionId || findFirstSectionIdByExamId(id)
        }/diagnostic`
      )
    }

  const redirectToScoreSheet = (id: string) => () => {
    push(`/exam/${id}/score-report/score-sheet`)
  }

  const getExamScore = (exam, scoreType) => {
    const scores = JSON.parse(R.propOr('{}', 'scores', exam))
    const examScores = R.propOr({}, 'exam', scores)
    return R.propOr('-', scoreType, examScores)
  }

  const getSectionsAsTableRows = row => {
    const sectionCount = R.pathOr(1, [
      'exam_length',
      'summary',
      'sectionCount'
    ])(row)

    if (sectionCount === 1) return []
    const sections: any[] = R.pathOr([], ['exam_length', 'sections'], row)
    if (isEmpty(sections)) {
      return []
    } else {
      const scores: any = JSON.parse(R.propOr('{}', 'scores', row))
      return sections.map((section, index) => {
        const sectionScores = isNotNilOrEmpty(scores)
          ? scores.sections.find(sec => sec.title === section.section)
          : {}
        return {
          ...R.pick(
            [
              'status',
              'time_option',
              'accessible_to',
              'completed_at',
              'id',
              'type_title'
            ],
            row
          ),
          sectionId: R.propOr(null, 'id', sectionScores),
          title: section.section,
          section_order: index + 1,
          exam_title: row.title,
          score_calculation_method: row.score_calculation_method,
          exam_length: {
            summary: {
              minutes: section.sectionMinutes,
              questions: section.amount
            }
          },
          scores: JSON.stringify({
            exam: {
              percentile_rank: R.propOr(null, 'percentile_rank', sectionScores),
              amount_correct: R.propOr(null, 'amount_correct', sectionScores),
              scaled_score: R.propOr(null, 'scaled_score', sectionScores)
            }
          })
        }
      })
    }
  }

  const isExamExpired = exam => {
    const accessibleTo = R.propOr('', 'accessible_to', exam)
    return isNotNilOrEmpty(accessibleTo) && isDateBeforeToday(accessibleTo)
  }

  const scoreCalculationMethod = exam =>
    pathOr('', ['score_calculation_method'], exam)

  const hideScaledScore = exam =>
    scoreCalculationMethod(exam) === SCORE_CALCULATION_METHOD.percentile

  const getExamStatus = exam => {
    // this is a workaround because API is not changing
    // the exam status to "expired" when un-started exam
    // gets expired
    return isExamExpired(exam)
      ? EXAM_STATUS.expired
      : R.pathOr(EXAM_STATUS.scheduled, ['status'], exam)
  }

  const isFreeTrialAsset = exam =>
    R.propOr(true, 'is_available', exam) === false &&
    R.propOr(true, 'is_free_trial_chapter', exam) === false

  const getRows = (source, isLvl2) => {
    return R.pipe(
      // @ts-ignore
      R.reject(R.propEq('status', EXAM_STATUS.unavailable)),
      // @ts-ignore
      mapIndexed((exam: Exam, index) => {
        const completionDone: number = R.propOr(0, 'completions_done', exam)
        const maxCompletions: number = R.propOr(0, 'max_completions', exam)
        const remainAttempts = maxCompletions - completionDone

        return {
          id: `row-exam-${exam.id}-${isLvl2 ? `row-${index}` : 'exam'}`,
          children: getRows(getSectionsAsTableRows(exam), true),
          cells: [
            {
              columnId: 'type',
              children: (
                <TypeContainer>{R.propOr('', 'title', exam)}</TypeContainer>
              )
            },
            {
              children: (
                <>
                  {getCalculatedMinutes(
                    R.pathOr(0, ['exam_length', 'summary', 'minutes'], exam),
                    exam.id
                  )}
                </>
              ),
              columnId: 'duration',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <TimeOptionContainer>
                  <SelectTimeOption
                    onChange={handleRefresh}
                    disabled={isLvl2}
                    timeOption={R.pathOr(
                      EXAM_TIME_OPTION_VALUES.regular,
                      ['time_option'],
                      exam
                    )}
                    examStatus={getExamStatus(exam)}
                    examId={exam.id}
                  />
                </TimeOptionContainer>
              ),
              columnId: 'time_option',
              cellProps: { align: 'center' }
            },
            {
              children:
                isFreeTrialCourse && isFreeTrialAsset(exam) ? (
                  '-'
                ) : (
                  <>
                    {
                      // @ts-ignore
                      exam.status === EXAM_STATUS.completed ? (
                        <div>
                          {t('examStatus.completed')}
                          <ExamCompletionDate>
                            {formatDate(
                              R.propOr('', 'completed_at', exam),
                              DATE_FORMATS.dashed
                            )}
                          </ExamCompletionDate>
                        </div>
                      ) : (
                        t(`examStatus.${getExamStatus(exam)}`)
                      )
                    }
                  </>
                ),
              columnId: 'status',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <AccessContainer>
                  <div
                    id={`${exam.id}-accessible-to`}
                    className='accessible-date'
                  >
                    {exam.accessible_to
                      ? formatDate(exam.accessible_to, DATE_FORMATS.dashed)
                      : '-'}
                  </div>
                  {isImpersonate && <ModalChangeAccessPeriod exam={exam} />}
                </AccessContainer>
              ),
              columnId: 'accessible_to',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <AccessContainer>
                  <RemainingAttemptsWrapper
                    id={`${exam.id}-remaining-attempts`}
                    className='remaining-attempts'
                  >
                    {remainAttempts}
                  </RemainingAttemptsWrapper>
                </AccessContainer>
              ),
              columnId: 'remaining_attempts',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <AccessContainer>
                  <RemainingAttemptsWrapper
                    id={`${exam.id}-remaining-attempts`}
                    className='remaining-attempts'
                  >
                    {completionDone > 0 && <RetakeOverallModal exam={exam} />}
                  </RemainingAttemptsWrapper>
                </AccessContainer>
              ),
              columnId: 'remaining_attempts',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <>
                  {
                    // @ts-ignore
                    getExamScore(exam, 'percentile_rank') === '-'
                      ? '-'
                      : // @ts-ignore
                        `${getExamScore(exam, 'percentile_rank')}%`
                  }
                </>
              ),
              columnId: 'rank',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <div>
                  {
                    // @ts-ignore
                    getExamScore(exam, 'amount_correct') === '-'
                      ? '-'
                      : `${getExamScore(exam, 'amount_correct')} of ${R.pathOr(
                          '-',
                          ['exam_length', 'summary', 'questions'],
                          exam
                        )}`
                  }
                </div>
              ),
              columnId: 'rawScore',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <div>
                  {hideScaledScore(exam)
                    ? '-'
                    : getExamScore(exam, 'scaled_score')}
                </div>
              ),
              columnId: 'scaledScore',
              cellProps: { align: 'center' }
            },
            {
              children: (
                <ButtonsContainer>
                  {R.pathOr(1, ['exam_length', 'summary', 'sectionCount'])(
                    exam
                  ) === 1 ? (
                    <IconButtons>
                      {isLvl2 ? null : (
                        <IconButton
                          variant='filled'
                          color='orange'
                          icon={<GaugeIcon />}
                          onClick={redirectToScoreSheet(exam.id)}
                          disabled={exam.status !== EXAM_STATUS.completed}
                        />
                      )}
                      <IconButton
                        variant='filled'
                        color='orange'
                        // @ts-ignore
                        disabled={exam.status !== EXAM_STATUS.completed}
                        icon={<McatForChartIcon />}
                        onClick={redirectToAnswerSheet(
                          exam.id,
                          R.propOr(null, 'sectionId', exam),
                          exam
                        )}
                      />
                      <IconButton
                        variant='filled'
                        color='orange'
                        // @ts-ignore
                        disabled={exam.status !== EXAM_STATUS.completed}
                        icon={<ChartIcon />}
                        onClick={redirectToDiagnostics(
                          exam.id,
                          R.propOr(null, 'sectionId', exam),
                          exam
                        )}
                      />
                    </IconButtons>
                  ) : (
                    <AlignedIconButtons>
                      <IconButton
                        variant='filled'
                        color='orange'
                        icon={<GaugeIcon />}
                        onClick={redirectToScoreSheet(exam.id)}
                        disabled={exam.status !== EXAM_STATUS.completed}
                      />
                    </AlignedIconButtons>
                  )}
                  {isNotNilOrEmpty(R.propOr('', 'type_type', exam)) ? (
                    <ExamButton
                      status={R.propOr(EXAM_STATUS.scheduled, 'status', exam)}
                      id={R.propOr('', 'id', exam)}
                      date={R.propOr('', 'accessible_to', exam)}
                      exam={exam}
                      isImpersonated={R.propOr(
                        false,
                        'is_impersonated',
                        studentDetails
                      )}
                    />
                  ) : (
                    <FakeButton />
                  )}
                  {isImpersonate &&
                    (isLvl2 ? <FakeExamLogs /> : <ModalExamLogs exam={exam} />)}
                </ButtonsContainer>
              ),
              columnId: 'action',
              cellProps: { width: '19%' }
            }
          ]
        }
      })
      // @ts-ignore
    )(source)
  }

  const rows = useMemo(() => {
    return getRows(examsList, false)
  }, [examsList])

  const headers = [
    {
      columnId: 'title',
      sortable: true,
      id: 'title',
      children: t('exams.table.headers.title')
    },
    {
      columnId: 'duration',
      sortable: true,
      id: 'duration',
      children: t('exams.table.headers.time'),
      align: 'center'
    },
    {
      columnId: 'time_option',
      sortable: false,
      id: 'time_option',
      children: t('exams.table.headers.time_option'),
      align: 'center'
    },
    {
      columnId: 'status',
      sortable: true,
      id: 'status',
      children: t('exams.table.headers.status'),
      align: 'center'
    },
    {
      columnId: 'accessible_to',
      sortable: true,
      id: 'accessible_to',
      children: t('exams.table.headers.expires'),
      align: 'center'
    },
    {
      columnId: 'remaining_attempts',
      sortable: false,
      id: 'remaining_attempts',
      children: t('exams.table.headers.remainingAttempts'),
      align: 'center'
    },
    {
      columnId: 'past_scores',
      sortable: false,
      id: 'past_scores',
      children: t(''),
      align: 'center'
    },
    {
      columnId: 'rank',
      sortable: false,
      id: 'rank',
      children: t('exams.table.headers.rank'),
      align: 'center'
    },
    {
      columnId: 'rawScore',
      sortable: false,
      id: 'rawScore',
      children: t('exams.table.headers.rawScore'),
      align: 'center'
    },
    {
      columnId: 'scaledScore',
      sortable: false,
      id: 'scaledScore',
      children: t('exams.table.headers.scaledScore'),
      align: 'center'
    },
    {
      columnId: 'action',
      sortable: false,
      id: 'action',
      children: t('exams.table.headers.action'),
      align: 'center'
    }
  ]

  function handleTableStateChange({ sortBy, dir, page, take }): void {
    const query = qs.stringify({
      order: { by: sortBy, dir: R.toLower(dir) },
      filter: {
        // @ts-ignore
        ...parsedQuery.filter
      },
      limit: { take, page }
    })
    replace(`${pathname}?${query}`)
  }

  useEffect(() => {
    fetchNavigation()

    isNilOrEmpty(search) &&
      handleTableStateChange({
        sortBy: 'status',
        dir: R.pathOr(SORT_DIRECTION.asc, ['order', 'dir'], parsedQuery),
        page: 1,
        take: R.pathOr(DEFAULT_ROWS_PER_PAGE, ['limit', 'take'], parsedQuery)
      })
  }, [])

  useEffect(() => {
    isNotNilOrEmpty(search) && fetchExams({ query: search })
  }, [search])

  // Rerender on refresh
  useEffect(() => {
    if (refresh) {
      setRefresh(false)
    }
  }, [refresh])

  useEffect(() => handleRefresh(), [examsList])

  const defaultRowsPerPage: number = R.pipe(
    R.pathOr(DEFAULT_ROWS_PER_PAGE, ['limit', 'take']),
    Number
  )(parsedQuery)

  return (
    <TableContainer isImpersonate={isImpersonate} id='exams-list-container'>
      <EntitiesList
        paginationPage={examsPagination.page}
        size='m'
        resultsText=''
        headers={headers}
        rows={rows}
        totalPages={examsPagination.pagesTotal}
        emptyStateText={t('exams.table.emptyState')}
        defaultPage={R.pathOr(1, ['limit', 'page'], parsedQuery)}
        defaultSortColumnId='status'
        defaultSortDirection={R.pathOr(
          SORT_DIRECTION.asc,
          ['order', 'dir'],
          parsedQuery
        )}
        defaultRowsPerPage={defaultRowsPerPage as 100 | 50 | 10}
        onTableStateChange={handleTableStateChange}
        highlight
      />
    </TableContainer>
  )
}

const TableContainer = styled.div`
  margin-top: -7px;
  & > div > div > div {
    overflow-x: unset;
  }

  table {
    thead {
      height: 40px;
    }

    button {
      min-width: 86px;
    }

    td {
      padding: ${({ isImpersonate }) => (isImpersonate ? '7px' : '7px 18px')};
      line-height: 1;
    }
  }

  .accessible-date {
    min-width: 85px;
  }
`

const TimeOptionContainer = styled.div`
  min-width: 120px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
`

const AccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ExamCompletionDate = styled.div`
  font-size: 11px !important;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.main.grey600};
`

const IconButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  button {
    min-width: unset !important;
  }
`

const AlignedIconButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 88px;
  gap: 12px;

  button {
    min-width: unset !important;
  }
`

const FakeButton = styled(Button)`
  opacity: 0;
  cursor: default;
  pointer-events: none;
`

const FakeExamLogs = styled.div`
  display: inline-block;
  min-width: 69px;
`

const RemainingAttemptsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export default TableExams
