import {
  addDays,
  format,
  isAfter,
  startOfMonth,
  endOfMonth,
  isSameDay,
  parse,
  addMinutes,
  isBefore
} from 'date-fns'
import {
  CalendarEventTypes,
  reorderCalendarEvents,
  updateCalendarEvent
} from 'services/CalendarService'

import { formatTestDate, cutTimeAndFormat, getUniversalDate } from 'utils/date'
import { isNotNil } from 'utils/ramda'

export type DatesObject = {
  [year: number]: {
    [month: string]: string[]
  }
}

// This function creates an object with all the days between the first day
// of the course start month and the last day of the month in which the final exam takes place.
// const blueprint = {
//   year: {
//     monthName: [firstDay to lastDay days]
//     ...more months
//   },
//   ...more years
// }
export const generateDateObject = (
  startDate: Date,
  endDate: Date
): DatesObject => {
  const result: DatesObject = {}

  const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd')

  const startCurrentMonth = startOfMonth(new Date(startDate))
  const endCurrentMonth = endOfMonth(endDate)

  let currentDateIter = new Date(startCurrentMonth)

  while (!isAfter(currentDateIter, endCurrentMonth)) {
    const year = currentDateIter.getFullYear()
    const month = format(currentDateIter, 'MMMM').toLowerCase()
    const formattedDate = formatDate(currentDateIter)

    if (!result[year]) {
      result[year] = {}
    }

    if (!result[year][month]) {
      result[year][month] = []
    }

    result[year][month].push(formattedDate)

    currentDateIter = addDays(currentDateIter, 1)
  }

  return result
}

// This function assigns the appropriate events to the days within the range
// const blueprint = {
//   date1: [date1 day events],
//   date2: [date2 day events]
//   ...more dates
// }
export const generateCalendarDays = (
  startDate: Date,
  endDate: Date,
  events
): DatesObject => {
  const result: DatesObject = {}

  const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd')

  const startCurrentMonth = startOfMonth(startDate)
  const endCurrentMonth = endOfMonth(endDate)

  let currentDateIter = new Date(startCurrentMonth)

  while (!isAfter(currentDateIter, endCurrentMonth)) {
    const formattedDate = formatDate(currentDateIter)
    const dateEvents = events.filter(
      e =>
        isSameDay(currentDateIter, cutTimeAndFormat(e.event_date)) &&
        !e.from_manual_setup
    )

    result[formattedDate] = [...(result[formattedDate] || []), ...dateEvents]

    currentDateIter = addDays(currentDateIter, 1)
  }

  return result
}

export function createWeeksArray(
  startDate: Date,
  endDate: Date
): Array<{ start: string; end: string; days: any[] }> {
  const weeks: Array<{ start: string; end: string; days: any[] }> = []
  let currentDate = new Date(startDate)
  const lastDate = new Date(endDate)

  // Adjust the start date to the previous Sunday if it's not already a Sunday
  currentDate.setDate(currentDate.getDate() - (currentDate.getDay() - 1))

  // Adjust the end date to the following Saturday if it's not already a Saturday
  lastDate.setDate(lastDate.getDate() + (6 - lastDate.getDay()))

  while (currentDate <= lastDate) {
    const startOfWeek = new Date(currentDate)
    const endOfWeek = new Date(currentDate)
    endOfWeek.setDate(endOfWeek.getDate() + 6) // Set to Saturday of the current week

    // Add the week to the array
    weeks.push({
      start: startOfWeek.toISOString().split('T')[0],
      end: endOfWeek.toISOString().split('T')[0],
      days: []
    })

    let dayOfWeek = 0
    while (dayOfWeek < 7) {
      const day = new Date(currentDate)
      const date = day.toISOString().split('T')[0]
      const monthName = new Intl.DateTimeFormat('en-US', {
        month: 'long'
      }).format(day)
      const year = day.getFullYear()

      weeks[weeks.length - 1].days.push({ date, monthName, year })

      currentDate.setDate(currentDate.getDate() + 1)
      dayOfWeek++
    }
  }

  return weeks
}

export const getMonthNumberByName = (monthName: string) => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]
  return String(months.indexOf(monthName) + 1).padStart(2, '0')
}

export const getStartDayOfMonth = (year: number, month: string): number => {
  const firstDayOfMonth = new Date(`${year}-${month}-01`)
  return firstDayOfMonth.getDay()
}

export const correctTimeZoneForDate = (value: string): Date => {
  const fixDate = new Date(formatTestDate(value))

  return fixDate
}

export const createCalendarRows = (monthDates: string[], startDay: number) => {
  const rows: string[][] = []
  let currentRow: string[] = []
  for (let i = 0; i < startDay; i++) {
    currentRow.push('')
  }

  monthDates.forEach((date, index) => {
    const rightDate = formatTestDate(date)

    const dayOfMonth = new Date(rightDate).getDate()

    currentRow.push(dayOfMonth.toString())

    if (currentRow.length === 7 || index === monthDates.length - 1) {
      rows.push(currentRow)
      currentRow = []
    }
  })

  return rows
}

export const createCalendarRowsFromWeeks = (week: string[]) => {
  const rows: string[][] = []
  let currentRow: string[] = []

  week.forEach((day, index) => {
    currentRow.push(day)

    if (currentRow.length === 7 || index === week.length - 1) {
      rows.push(currentRow)
      currentRow = []
    }
  })

  return rows
}

export const weekdayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const uiReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export const apiReorder = async (list, startIndex, endIndex) => {
  const result: CalendarEventTypes[] = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)
  const ids = result.map(el => el.id)
  const response = await reorderCalendarEvents({ ids })

  if ([200, 201].includes(response.status)) {
    return result
  } else {
    return list
  }
}

export const apiMove = async (
  source,
  destination,
  droppableSource,
  droppableDestination,
  eventId,
  fromArchive = false,
  toArchive = false
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)

  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const newDate = droppableDestination.droppableId
  const order = droppableDestination.index + 1

  const status = fromArchive
    ? { status: 'incomplete' }
    : toArchive
    ? { status: 'archived' }
    : {}

  const response = await updateCalendarEvent({
    id: eventId,
    event_date: newDate,
    order,
    ...status
  })

  if ([200, 201].includes(response.status)) {
    return {
      source: sourceClone,
      destination: destClone.map(e => {
        // @ts-ignore
        if (e.id === eventId) {
          return response.data
        } else {
          return e
        }
      })
    }
  } else {
    return { source, destination }
  }
}

interface UIMoveProps {
  source: any[]
  destination: any[]
  droppableSource?: any
  droppableDestination?: any
  isFromArchive?: boolean
  destinationDate?: string
}

export const uiMove = (
  source,
  destination,
  droppableSource,
  droppableDestination,
  isFromArchive?,
  destinationDate?
): UIMoveProps => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(
    droppableDestination.index,
    0,
    isFromArchive
      ? {
          // @ts-ignore
          ...removed,
          event_date: `${destinationDate}T00:00:00.000Z`,
          status: 'incomplete'
        }
      : removed
  )

  const result = { source: [], destination: [] }

  // @ts-ignore
  result.source = sourceClone
  // @ts-ignore
  result.destination = destClone

  return result
}

export const apiMoveToArchive = async (source, droppableSource, eventId) => {
  const sourceClone = Array.from(source)

  sourceClone.splice(droppableSource.index, 1)

  const response = await updateCalendarEvent({
    id: eventId,
    status: 'archived',
    event_date: new Date('2030-04-04')
  })

  if ([200, 201].includes(response.status)) {
    return {
      source: sourceClone,
      event: response.data
    }
  } else {
    return { source, event: null }
  }
}

export const uiMoveToArchive = (source, droppableSource) => {
  const sourceClone = Array.from(source)
  sourceClone.splice(droppableSource.index, 1)

  return { source: sourceClone }
}

export const formatEventTime = (minutes: number) => {
  if (isNaN(minutes) || minutes < 0) {
    return 'Invalid time'
  }

  const h = Math.floor(minutes / 60)
  const min = Math.floor((minutes % 60) / 60)

  const hoursStr = h > 0 ? `${h}h` : ''
  const minutesStr = min > 0 ? `${min}m` : ''

  return `${hoursStr} ${minutesStr}`.trim() || '0m'
}

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getRandomColor = () => {
  const colors = ['green', 'red', 'blue', 'yellow']
  const randomIndex = getRandomInt(0, colors.length - 1)
  return colors[randomIndex]
}

export const getCalendarMonthCaption = (month: string, year: string) => {
  return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`
}

export const getMonthName = monthNumber => {
  const date = new Date(2020, Number(monthNumber) - 1)
  return date.toLocaleString('en-GB', { month: 'long' })
}

export const eventColorsTranslator = (color: string): string => {
  switch (color) {
    case 'ultramarine':
      return 'red'
    case 'gold':
      return 'orange'
    case 'mathPurple':
      return 'purple'
    case 'green':
      return 'green'
    case 'blue':
      return 'blue'
    case 'brown':
      return 'brown'
    case 'turquoise':
      return 'turquoise'
    default:
      return 'linear-gradient(180deg, #FFEEDF 0%, #FFDCBB 100%)'
  }
}

export const statusOptions = (excluded?: string[]) => {
  const options = [
    { label: 'Incomplete', value: 'incomplete' },
    { label: 'Complete', value: 'complete' },
    { label: 'Skipped', value: 'skipped' },
    { label: 'Archived', value: 'archived' }
  ]

  if (excluded) {
    return options.filter(el => !excluded.includes(el.value))
  }

  return options
}

export type CalendarEventType = {
  chapter: string
  fullLengthExam: string
  fullLengthExamReview: string
  chapterExam: string
  chapterExamReview: string
  sectionExam: string
  sectionExamReview: string
  custom: string
  halfLengthExam: string
  customEventType: string
  customFullLengthExamReview: string
  customSectionExamReview: string
  customSectionExam: string
  customFullLengthExam: string
  otherExam: string
  otherExamReview: string
  bookLinkRreReading: string
  liveClass: string
  customLiveClass: string
  customEndDateEvent: string
}

export enum CALENDAR_EVENT_STATUSES {
  incomplete = 'incomplete',
  complete = 'complete',
  skipped = 'skipped'
}

export const calendarEventType: CalendarEventType = {
  chapter: 'book_link',
  fullLengthExam: 'full_length_exam',
  fullLengthExamReview: 'full_length_exam_review',
  chapterExam: 'chapter_exam',
  chapterExamReview: 'chapter_exam_review',
  sectionExam: 'section_exam',
  sectionExamReview: 'section_exam_review',
  halfLengthExam: 'half_length_exam',
  customEventType: 'custom_event_type',
  customFullLengthExamReview: 'custom_full_length_exam_review',
  customSectionExamReview: 'custom_section_exam_review',
  customSectionExam: 'custom_section_exam',
  customFullLengthExam: 'custom_full_length_exam',
  custom: 'custom',
  otherExam: 'other_exam',
  otherExamReview: 'other_exam_review',
  bookLinkRreReading: 'book_link_pre_reading',
  liveClass: 'live_class',
  customLiveClass: 'custom_live_class', // this enum value is for alternative live classes chosen by student
  customEndDateEvent: 'custom_end_date_event' // this enum value is for custom classes created by Admin
}

export const calendarEventTypeLabel = (type: string) => {
  switch (type) {
    case calendarEventType.chapter:
      return 'Reading'
    case calendarEventType.fullLengthExam:
      return 'Full Len. Exam'
    case calendarEventType.fullLengthExamReview:
      return 'Full Len. Exam Rev.'
    case calendarEventType.chapterExam:
      return 'Mini-MCAT'
    case calendarEventType.chapterExamReview:
      return 'Review'
    case calendarEventType.sectionExam:
      return 'S. Exam'
    case calendarEventType.sectionExamReview:
      return 'S. Exam Rev.'
    case calendarEventType.halfLengthExam:
      return 'Half-Length MCAT'
    case calendarEventType.bookLinkRreReading:
      return 'Pre-Reading'
    case calendarEventType.liveClass:
      return 'Class'
    case calendarEventType.customLiveClass:
      return 'Class'
    case calendarEventType.custom:
      return ''

    default:
      return ''
  }
}

export const calendarEventTypeLabelLong = (type: string) => {
  switch (type) {
    case calendarEventType.chapter:
      return 'Reading'
    case calendarEventType.bookLinkRreReading:
      return 'Pre-Reading'
    case calendarEventType.fullLengthExam:
      return 'Full Length MCAT'
    case calendarEventType.fullLengthExamReview:
      return 'Full Length MCAT Review'
    case calendarEventType.chapterExam:
      return 'Mini-MCAT'
    case calendarEventType.chapterExamReview:
      return 'Review'
    case calendarEventType.sectionExam:
      return 'Sec'
    case calendarEventType.sectionExamReview:
      return 'Sec'
    case calendarEventType.halfLengthExam:
      return 'Half-Length MCAT'
    case calendarEventType.custom:
      return ''
    case calendarEventType.customFullLengthExam:
      return 'Full Length MCAT'
    case calendarEventType.customFullLengthExamReview:
      return 'Full Length MCAT Review'
    case calendarEventType.customSectionExam:
      return 'Sec'
    case calendarEventType.customSectionExamReview:
      return 'Sec Rev'
    case calendarEventType.otherExam:
      return 'Half-Length MCAT'
    case calendarEventType.otherExamReview:
      return 'Half-Length MCAT Review'
    default:
      return ''
  }
}

export const isHalfLengthExam = (type: string, title: string) => {
  if (
    type === calendarEventType.customFullLengthExam &&
    title === 'Half Lenght Exam'
  ) {
    return 'Half-Length MCAT'
  }
  return ''
}

export const eventDetailsDescription = (type: string) => {
  switch (type) {
    case calendarEventType.chapter:
      return 'Reading'
    case calendarEventType.bookLinkRreReading:
      return 'Pre-Reading'
    case calendarEventType.fullLengthExam:
      return 'Full Length MCAT'
    case calendarEventType.fullLengthExamReview:
      return 'Full Length MCAT Review'
    case calendarEventType.chapterExam:
      return 'Mini-MCAT'
    case calendarEventType.chapterExamReview:
      return 'Review'
    case calendarEventType.sectionExam:
      return 'Sec'
    case calendarEventType.sectionExamReview:
      return 'Sec'
    case calendarEventType.halfLengthExam:
      return 'Half-Length MCAT'
    case calendarEventType.custom:
      return ''
    case calendarEventType.customFullLengthExam:
      return 'Full Length MCAT'
    case calendarEventType.customFullLengthExamReview:
      return 'Full Length MCAT Review'
    case calendarEventType.customSectionExam:
      return 'Section Exam'
    case calendarEventType.customSectionExamReview:
      return 'Section Exam Review'
    case calendarEventType.otherExam:
      return 'Half-Length MCAT'
    case calendarEventType.otherExamReview:
      return 'Half-Length MCAT Review'
    default:
      return ''
  }
}

export const hourOptions = [
  { label: '0h', value: 0 },
  { label: '1h', value: 60 },
  { label: '2h', value: 120 },
  { label: '3h', value: 180 },
  { label: '4h', value: 240 },
  { label: '5h', value: 300 },
  { label: '6h', value: 360 },
  { label: '7h', value: 420 },
  { label: '8h', value: 480 }
]

export const minuteOptions = [
  { label: '0m', value: 0 },
  { label: '5m', value: 5 },
  { label: '10m', value: 10 },
  { label: '15m', value: 15 },
  { label: '20m', value: 20 },
  { label: '25m', value: 25 },
  { label: '30m', value: 30 },
  { label: '35m', value: 35 },
  { label: '40m', value: 40 },
  { label: '45m', value: 45 },
  { label: '50m', value: 50 },
  { label: '55m', value: 55 }
]

export const timeOptions = () => [
  { label: '15m', value: 15 },
  { label: '30m', value: 30 },
  { label: '45m', value: 45 },
  { label: '1h', value: 60 },
  { label: '1h 15m', value: 75 },
  { label: '1h 30m', value: 90 },
  { label: '1h 45m', value: 105 },
  { label: '2h', value: 120 },
  { label: '2h 15m', value: 135 },
  { label: '2h 30m', value: 150 },
  { label: '2h 45m', value: 165 },
  { label: '3h', value: 180 },
  { label: '3h 15m', value: 195 },
  { label: '3h 30m', value: 210 },
  { label: '3h 45m', value: 225 },
  { label: '4h', value: 240 },
  { label: '4h 15m', value: 255 },
  { label: '4h 30m', value: 270 },
  { label: '4h 45m', value: 285 },
  { label: '5h', value: 300 },
  { label: '5h 15m', value: 315 },
  { label: '5h 30m', value: 330 },
  { label: '5h 45m', value: 345 },
  { label: '6h', value: 360 },
  { label: '6h 15m', value: 375 },
  { label: '6h 30m', value: 390 },
  { label: '6h 45m', value: 405 },
  { label: '7h', value: 420 }
]

export const radioButtonOptions = {
  confirm: {
    label: 'Yes',
    value: 'yes'
  },
  reject: {
    label: 'No',
    value: 'no'
  }
}

export const weekDays = {
  sunday: { id: 'sunday', value: 'Sunday' },
  monday: { id: 'monday', value: 'Monday' },
  tuesday: { id: 'tuesday', value: 'Tuesday' },
  wednesday: { id: 'wednesday', value: 'Wednesday' },
  thursday: { id: 'thursday', value: 'Thursday' },
  friday: { id: 'friday', value: 'Friday' },
  saturday: { id: 'saturday', value: 'Saturday' }
}
// List of columns which are independent drop zones
export const columns = {
  sunday: { id: 'sunday', title: 'Sunday', day: [weekDays.sunday] },
  monday: {
    id: 'monday',
    title: 'Monday',
    day: [weekDays.monday]
  },
  tuesday: { id: 'tuesday', title: 'Tuesday', day: [weekDays.tuesday] },
  wednesday: {
    id: 'wednesday',
    title: 'Wednesday',
    day: [weekDays.wednesday]
  },
  thursday: { id: 'thursday', title: 'Thursday', day: [weekDays.thursday] },
  friday: { id: 'friday', title: 'Friday', day: [weekDays.friday] },
  saturday: { id: 'saturday', title: 'Saturday', day: [weekDays.saturday] },
  mcatDay: { id: 'mcatDay', title: 'FL-MCAT Day', day: [] },
  reviewDay: { id: 'reviewDay', title: 'FL-MCAT Rev. Day', day: [] },
  priorityOne: { id: 'priorityOne', title: '1st Priority', day: [] },
  priorityTwo: { id: 'priorityTwo', title: '2nd Priority', day: [] },
  priorityThree: { id: 'priorityThree', title: '3rd Priority', day: [] },
  priorityFour: { id: 'priorityFour', title: '4th Priority', day: [] },
  priorityFive: { id: 'priorityFive', title: '5th Priority', day: [] }
}
export const columnsForExpiration = {
  sunday: { id: 'sunday', title: 'Sunday', day: [weekDays.sunday] },
  monday: {
    id: 'monday',
    title: 'Monday',
    day: [weekDays.monday]
  },
  tuesday: { id: 'tuesday', title: 'Tuesday', day: [weekDays.tuesday] },
  wednesday: {
    id: 'wednesday',
    title: 'Wednesday',
    day: [weekDays.wednesday]
  },
  thursday: { id: 'thursday', title: 'Thursday', day: [weekDays.thursday] },
  friday: { id: 'friday', title: 'Friday', day: [weekDays.friday] },
  saturday: { id: 'saturday', title: 'Saturday', day: [weekDays.saturday] },
  priorityOne: { id: 'priorityOne', title: '1st Priority', day: [] },
  priorityTwo: { id: 'priorityTwo', title: '2nd Priority', day: [] },
  priorityThree: { id: 'priorityThree', title: '3rd Priority', day: [] },
  priorityFour: { id: 'priorityFour', title: '4th Priority', day: [] },
  priorityFive: { id: 'priorityFive', title: '5th Priority', day: [] },
  prioritySix: { id: 'prioritySix', title: '6th Priority', day: [] },
  prioritySeven: { id: 'prioritySeven', title: '7th Priority', day: [] }
}

export const prevStateOfCalendarColumns = (dataArray: number[]) => {
  const columns = {
    sunday: { id: 'sunday', title: 'Sunday', day: [] },
    monday: {
      id: 'monday',
      title: 'Monday',
      day: []
    },
    tuesday: { id: 'tuesday', title: 'Tuesday', day: [] },
    wednesday: {
      id: 'wednesday',
      title: 'Wednesday',
      day: []
    },
    thursday: { id: 'thursday', title: 'Thursday', day: [] },
    friday: { id: 'friday', title: 'Friday', day: [] },
    saturday: { id: 'saturday', title: 'Saturday', day: [] },
    mcatDay: { id: 'mcatDay', title: 'FL-MCAT Day', day: [] },
    reviewDay: { id: 'reviewDay', title: 'FL-MCAT Rev. Day', day: [] },
    priorityOne: { id: 'priorityOne', title: '1st Priority', day: [] },
    priorityTwo: { id: 'priorityTwo', title: '2nd Priority', day: [] },
    priorityThree: { id: 'priorityThree', title: '3rd Priority', day: [] },
    priorityFour: { id: 'priorityFour', title: '4th Priority', day: [] },
    priorityFive: { id: 'priorityFive', title: '5th Priority', day: [] }
  }

  const updatedColumns = columns

  dataArray.forEach((index, day) => {
    const rightIndex = () => {
      if (index === 1) {
        return 9
      } else if (index === 2) {
        return 10
      } else if (index === 3) {
        return 11
      } else if (index === 4) {
        return 12
      } else if (index === 5) {
        return 13
      } else if (index === 6) {
        return 7
      } else if (index === 7) {
        return 8
      } else {
        return day
      }
    }
    const columnId = columnsOrder[rightIndex()]

    updatedColumns[columnId].day = [weekDays[daysOfWeek[day]]]
  })
  return updatedColumns
}
export const prevStateOfCalendarColumnsForExpiration = (
  dataArray: number[]
) => {
  const columns = {
    sunday: { id: 'sunday', title: 'Sunday', day: [] },
    monday: {
      id: 'monday',
      title: 'Monday',
      day: []
    },
    tuesday: { id: 'tuesday', title: 'Tuesday', day: [] },
    wednesday: {
      id: 'wednesday',
      title: 'Wednesday',
      day: []
    },
    thursday: { id: 'thursday', title: 'Thursday', day: [] },
    friday: { id: 'friday', title: 'Friday', day: [] },
    saturday: { id: 'saturday', title: 'Saturday', day: [] },
    priorityOne: { id: 'priorityOne', title: '1st Priority', day: [] },
    priorityTwo: { id: 'priorityTwo', title: '2nd Priority', day: [] },
    priorityThree: { id: 'priorityThree', title: '3rd Priority', day: [] },
    priorityFour: { id: 'priorityFour', title: '4th Priority', day: [] },
    priorityFive: { id: 'priorityFive', title: '5th Priority', day: [] },
    prioritySix: { id: 'prioritySix', title: '6th Priority', day: [] },
    prioritySeven: { id: 'prioritySeven', title: '7th Priority', day: [] }
  }

  const updatedColumns = columns

  dataArray.forEach((index, day) => {
    const rightIndex = () => {
      if (index === 1) {
        return 7
      } else if (index === 2) {
        return 8
      } else if (index === 3) {
        return 9
      } else if (index === 4) {
        return 10
      } else if (index === 5) {
        return 11
      } else if (index === 6) {
        return 12
      } else if (index === 7) {
        return 13
      } else {
        return day
      }
    }
    const columnId = columnsOrderForExpiration[rightIndex()]

    updatedColumns[columnId].day = [weekDays[daysOfWeek[day]]]
  })
  return updatedColumns
}

// Order of the columns
export const columnsOrder = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'mcatDay',
  'reviewDay',
  'priorityOne',
  'priorityTwo',
  'priorityThree',
  'priorityFour',
  'priorityFive'
]
export const columnsOrderForExpiration = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'priorityOne',
  'priorityTwo',
  'priorityThree',
  'priorityFour',
  'priorityFive',
  'prioritySix',
  'prioritySeven'
]

export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const countStudyDaysInRange = (
  startDate: string,
  endDate: string,
  prioriDays: number[]
) => {
  if (startDate && endDate && prioriDays) {
    const studyDays = prioriDays
      .map((day, index) => (![0, 6, 7].includes(day) ? index : null))
      .filter(el => isNotNil(el))

    const start = getUniversalDate(startDate)
    const end = getUniversalDate(endDate)

    let count = 0
    let currentDate = start

    while (currentDate <= end) {
      const dateDay = currentDate.getDay()

      if (studyDays.includes(dateDay)) count++

      currentDate = addDays(currentDate, 1)
    }
    return count
  }

  return 9999
}

export const countStudyDaysInRangeForExpiration = (startDate, endDate) => {
  if (startDate && endDate) {
    const start = getUniversalDate(startDate)
    const end = getUniversalDate(endDate)

    let count = 0
    let currentDate = start

    while (currentDate <= end) {
      count++
      currentDate = addDays(currentDate, 1)
    }

    return count
  }

  return 9999
}

export function generateCalendar(
  year: number,
  targetMonth: string
): { [year: number]: { [month: string]: string[] } } {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]

  const targetIndex = months.indexOf(targetMonth)

  if (targetIndex === -1) {
    return {}
  }

  const calendars: { [year: number]: { [month: string]: string[] } } = {}
  let currentYear = year
  let calendar: { [month: string]: string[] } = {}

  // If the target month is January, start from the previous year
  if (targetMonth === 'january') {
    currentYear--
  }

  const startIndex = (targetIndex - 1 + 12) % 12
  const endIndex = (targetIndex + 2) % 12

  for (let i = startIndex; i !== endIndex; i = (i + 1) % 12) {
    const month = months[i]
    const daysInMonth = new Date(Date.UTC(currentYear, i + 1, 0)).getUTCDate() // Corrected the month index here
    const monthDates: string[] = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(Date.UTC(currentYear, i, day))
      const formattedDate = date.toISOString().slice(0, 10)
      monthDates.push(formattedDate)
    }

    calendar[month] = monthDates

    // If we've reached the end of the year, add the current calendar to the object and start a new one
    if (i === 11) {
      calendars[currentYear] = calendar
      currentYear++
      calendar = {}
    }
  }

  // Add the last calendar to the object
  if (Object.keys(calendar).length > 0) {
    calendars[currentYear] = calendar
  }

  return calendars
}

const typeOrder = {
  other_exam: 0,
  full_length_exam: 1,
  custom_full_length_exam: 2,
  custom_section_exam: 3
}
const typeOrderForReview = {
  other_exam_review: 0,
  full_length_exam_review: 1,
  custom_full_length_exam_review: 2,
  custom_section_exam_review: 3
}

export const sortArchivedEventsByType = events => {
  return {
    readings: events
      .filter(e => e.type === 'book_link')
      .sort((a, b) => {
        const titleA = a.title.match(/(\D+)(\d+)/)
        const titleB = b.title.match(/(\D+)(\d+)/)

        // Compare the non-numeric parts
        const textComparison = titleA[1].localeCompare(titleB[1])

        if (textComparison !== 0) {
          return textComparison // If non-numeric parts differ, return that result
        }

        // If non-numeric parts are the same, compare the numeric parts
        return parseInt(titleA[2]) - parseInt(titleB[2])
      }),
    mini_mcats: events
      .filter(e => e.type === 'chapter_exam')
      .sort((a, b) => {
        const titleA = a.title.match(/(\D+)(\d+)/)
        const titleB = b.title.match(/(\D+)(\d+)/)

        // Compare the non-numeric parts
        const textComparison = titleA[1].localeCompare(titleB[1])

        if (textComparison !== 0) {
          return textComparison // If non-numeric parts differ, return that result
        }

        // If non-numeric parts are the same, compare the numeric parts
        return parseInt(titleA[2]) - parseInt(titleB[2])
      }),
    reviews: events
      .filter(e => e.type === 'chapter_exam_review')
      .sort((a, b) => {
        const titleA = a.title.match(/(\D+)(\d+)/)
        const titleB = b.title.match(/(\D+)(\d+)/)

        // Compare the non-numeric parts
        const textComparison = titleA[1].localeCompare(titleB[1])

        if (textComparison !== 0) {
          return textComparison // If non-numeric parts differ, return that result
        }

        // If non-numeric parts are the same, compare the numeric parts
        return parseInt(titleA[2]) - parseInt(titleB[2])
      }),
    exams: events
      .filter(e => Object.keys(typeOrder).includes(e.type)) // Filter based on defined types
      .sort((a, b) => {
        // Compare the types based on the defined order
        const typeComparison = typeOrder[a.type] - typeOrder[b.type]
        if (typeComparison !== 0) {
          return typeComparison // If types differ, return that result
        }

        // If types are the same, proceed with title comparison
        const titleA = a.title.match(/(\D+)(\d+)/)
          ? a.title.match(/(\D+)(\d+)/)
          : a.title.match(/(\D+)/)
        const titleB = b.title.match(/(\D+)(\d+)/)
          ? b.title.match(/(\D+)(\d+)/)
          : b.title.match(/(\D+)/)

        // Compare the non-numeric parts
        const textComparison = titleA[1].localeCompare(titleB[1])
        if (textComparison !== 0) {
          return textComparison // If non-numeric parts differ, return that result
        }

        // If non-numeric parts are the same, compare the numeric parts
        return parseInt(titleA[2]) - parseInt(titleB[2])
      }),
    exam_reviews: events
      .filter(e => {
        // Check if the event's type includes any of the defined types
        return Object.keys(typeOrderForReview).some(type =>
          e.type.includes(type)
        )
      })
      .sort((a, b) => {
        // Find the type for each event
        const typeA = Object.keys(typeOrderForReview).find(type =>
          a.type.includes(type)
        )
        const typeB = Object.keys(typeOrderForReview).find(type =>
          b.type.includes(type)
        )

        // Ensure typeA and typeB are defined before accessing typeOrderForReview
        const orderA = typeA ? typeOrderForReview[typeA] : -1 // Default to -1 if not found
        const orderB = typeB ? typeOrderForReview[typeB] : -1 // Default to -1 if not found

        // Compare the types based on the defined order
        const typeComparison = orderA - orderB

        if (typeComparison !== 0) {
          return typeComparison // If types differ, return that result
        }

        // If types are the same, proceed with title comparison
        const titleA = a.title.match(/(\D+)(\d+)/)
        const titleB = b.title.match(/(\D+)(\d+)/)

        // Check if titleA and titleB matched correctly
        if (!titleA || !titleB) {
          return 0 // Consider equal if format is incorrect
        }

        // Compare the non-numeric parts
        const textComparison = titleA[1].trim().localeCompare(titleB[1].trim())

        if (textComparison !== 0) {
          return textComparison // If non-numeric parts differ, return that result
        }

        // If non-numeric parts are the same, compare the numeric parts
        const numericA = parseInt(titleA[2], 10)
        const numericB = parseInt(titleB[2], 10)

        // Check for NaN values
        if (isNaN(numericA) || isNaN(numericB)) {
          return 0 // Consider equal if numeric parsing fails
        }

        return numericA - numericB
      }),

    prereading: events
      .filter(e => ['book_link_pre_reading'].includes(e.type))
      .sort((a, b) => a.title.localeCompare(b.title))
  }
}

export const ARCHIVE_TAB_TYPES = {
  book_link: 'readings',
  chapter_exam: 'mini_mcats',
  chapter_exam_review: 'reviews',
  custom_section_exam: 'exams',
  other_exam: 'exams',
  full_length_exam: 'exams',
  custom_full_length_exam: 'exams',
  custom_section_exam_review: 'exam_reviews',
  other_exam_review: 'exam_reviews',
  full_length_exam_review: 'exam_reviews',
  book_link_pre_reading: 'prereading'
}

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS = 2

export const firstClassDateCalendar = classDate => {
  const date = new Date(classDate)

  // Subtract one day (24 hours in milliseconds)
  date.setTime(date.getTime() - 24 * 60 * 60 * 1000)

  // Format the date to YYYY-MM-DD
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')

  // Return the formatted date string
  return `${year}-${month}-${day}T00:00:00.000Z`
}

export const checkEventTiming = event => {
  const { event_date, class_time, class_time_end } = event
  const eventDate = event_date.split('T')[0]

  const parseEventDateTime = time =>
    parse(`${eventDate} ${time}`, 'yyyy-MM-dd HH:mm', new Date())

  const eventStart = parseEventDateTime(class_time)
  const eventEnd = parseEventDateTime(class_time_end)

  const bufferStart = addMinutes(eventStart, -15)
  const bufferEnd = addMinutes(eventEnd, 15)

  const now = new Date()
  const isEventDay = isSameDay(now, eventStart)

  const isBeforeBuffer = isBefore(now, bufferStart)
  const isAfterBuffer = isAfter(now, bufferEnd)
  const isDuringEvent = isEventDay && !isBeforeBuffer && !isAfterBuffer

  return isDuringEvent
}
