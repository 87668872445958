import APIService from 'services/APIService'
import { FetchSettingsByNamespacePayload } from 'types'

const api = APIService()

export const fetchSettingsByNamespace = (
  payload: FetchSettingsByNamespacePayload
) => api.get(`/app-settings/namespace/${payload.namespace}`)

export const setVideosBGMusic = (payload: boolean) =>
  api.patch(`/students/set-video-bg-music`, { video_bg_music_enabled: payload })

export const setCQAnimations = (payload: boolean) =>
  api.patch(`/students/set-cq-animations`, {
    cq_animations_enabled: payload
  })

export const setTheme = (payload: string) =>
  api.patch(`/students/set-theme`, { theme: payload })

export const toggleGettingStartedHelper = (payload: boolean) => {
  return payload
    ? api.patch('/students/getting-started-incomplete')
    : api.patch('/students/getting-started-completed')
}
