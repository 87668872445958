import APIService from 'services/APIService'

const api = APIService()

export const authStudent = payload =>
  api.post(
    '/students/sync-student',
    {},
    { headers: { 'x-Proxy-Authorization': payload.token } }
  )

export const refreshTokenStudent = () => api.post('/students/refresh-token')

export const fetchStudentDetails = () => api.get('/students/profile')

export const updateStudentUsername = username =>
  api.patch('/students/username', { username })

export const createGladiatorGameToken = () =>
  api.post('/user-tokens/refresh-token')

export const sendVerificationCode = payload =>
  api.post(
    '/student-two-factor-authentication/send-code',
    {},
    { headers: { 'x-Proxy-Authorization': payload.token } }
  )

export const sendVerificationCodeViaEmail = payload =>
  api.post(
    '/student-two-factor-authentication/send-code?as_email=true',
    {},
    { headers: { 'x-Proxy-Authorization': payload.token } }
  )

export const verifySMSCode = payload =>
  api.post(
    `/student-two-factor-authentication/verify-code`,
    { code: payload.code },
    { headers: { 'x-Proxy-Authorization': payload.token } }
  )

export const checkPlivoStatus = () =>
  api.get('/student-two-factor-authentication/is-enabled')

export const getEmailResendStatus = () =>
  api.get('/app-settings/namespace/2_factor_authentication')
