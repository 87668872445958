import React from 'react'
import styled from 'styled-components'

interface CustomCourseLogoProps {
  imageUrl: string
  altText?: string
}

const CustomCourseLogo: React.FC<CustomCourseLogoProps> = ({
  imageUrl,
  altText = 'Course Logo'
}) => {
  return (
    <LogoContainer>
      <LogoImage src={imageUrl} alt={altText} />
    </LogoContainer>
  )
}

export default CustomCourseLogo

const LogoContainer = styled.div`
  width: 149px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -20px 0 16px;
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
