import React, { useEffect, useState } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'

import { getBookContentFlashcardsBySubchapterId } from 'modules/books/ducks/selectors'
import { BOOK_VIEWER_PARAMS } from 'utils/books'
import { SuspenseFadeIn } from 'examkrackers-components'

import { RootReducer } from 'types'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/flashcards/utils/events'

const FlashcardAnswer = React.lazy(
  () => import('modules/flashcards/components/FlashcardAnswer')
)

export const FlashcardsPreview = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const subchapterId = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const rightTopExpand = propOr(
    'false',
    BOOK_VIEWER_PARAMS.rightTopExpand
  )(parsedQuery)

  const flashcards = useSelector((state: RootReducer) =>
    getBookContentFlashcardsBySubchapterId(state, subchapterId)
  )

  const getFlashcardByIndex = flashcards[currentSlide]
  const pagesTotal = flashcards.length
  const currentPage = pagesTotal === 0 ? currentSlide : currentSlide + 1

  const changePage = newPage => () => {
    setCurrentSlide(newPage - 1)
    eventEmitter.emit(events.loadingFlashcardAnswer, false)
  }

  useEffect(() => {
    setCurrentSlide(0)
  }, [subchapterId])

  const handleSuccessFetch = () =>
    eventEmitter.emit(events.loadingFlashcardAnswer, false)

  const handleSuccessAnswer = () => {
    handleSuccessFetch()

    if (currentPage === pagesTotal) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(prev => prev + 1)
    }
  }

  return (
    <Container data-testid='FlashcardsPreviewContainer'>
      <SuspenseFadeIn>
        <FlashcardAnswer
          data-testid='FlashcardAnswer'
          size={rightTopExpand === 'false' ? 'small' : 'large'}
          handleNext={changePage(currentPage + 1)}
          onSuccessAnswer={handleSuccessAnswer}
          disableNext={pagesTotal === currentPage || pagesTotal === 0}
          handlePrevious={changePage(currentPage - 1)}
          disablePrevious={currentPage === 1 || pagesTotal === 0}
          flashcard={getFlashcardByIndex}
          isABookView
          numberTotal={pagesTotal}
          numberCurrent={currentPage}
        />
      </SuspenseFadeIn>
    </Container>
  )
}

export default FlashcardsPreview

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 8px;
  overflow: hidden;
`
