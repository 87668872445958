import styled from 'styled-components'

const Card = styled.div<{ $isDark?: boolean }>`
  position: relative;
  border-radius: 8px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background-color: ${({ $isDark }) => ($isDark ? '#2C2C2C' : 'white')};
  //width: 400px;
  flex-grow: 1;
  height: 306px;
`

const CardHeader = styled.div<{ $isDark?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  color: ${({ $isDark }) => ($isDark ? 'white' : 'inherit')};
`

const Icon = styled.span`
  font-size: 24px;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.main.white};
  position: relative;
  top: -4px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const Title = styled.h2<{ $isDark?: boolean }>`
  margin: 0;
  font-size: 16px;
  position: relative;
  top: -6px;
  color: ${({ $isDark }) => ($isDark ? 'white' : 'inherit')};
`

const ScrollableContainer = styled.div<{ $isDark?: boolean }>`
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  background-color: ${({ $isDark }) => ($isDark ? '#2C2C2C' : 'white')};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ $isDark }) => ($isDark ? '#555' : '#ccc')};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ $isDark }) => ($isDark ? '#333' : '#f1f1f1')};
  }
`

const NoEventsContainer = styled.div<{ $isDark?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ $isDark }) => ($isDark ? '#333' : '#f5f5f5')};
`

const NoEventsMessage = styled.p<{ $isDark?: boolean }>`
  text-align: center;
  color: ${({ $isDark }) => ($isDark ? '#ccc' : 'inherit')};
`

const TotalDurationContainer = styled.div<{ $isDark?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isDark }) => ($isDark ? '#444' : 'inherit')};
  color: ${({ $isDark }) => ($isDark ? 'white' : 'inherit')};
  padding: 4px 12px 4px 8px;
  border-radius: 8px;
`

const IconContainer = styled.div`
  background-color: black;
  border-radius: 4px;
  padding: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export {
  Card,
  CardHeader,
  Icon,
  CardContent,
  Title,
  ScrollableContainer,
  NoEventsContainer,
  NoEventsMessage,
  TotalDurationContainer,
  IconContainer
}
