import React from 'react'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import qs from 'qs'
import * as R from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import {
  getGlossaryPhrasesByAvailableBooks,
  getIsGlossaryLoading
} from 'modules/glossary/ducks/selectors'
import {
  fetchGlossaryWordsListRoutine,
  resetGlossaryWordsListRoutine
} from 'modules/glossary/ducks/actions'
import { SORT_DIRECTION } from 'utils/table'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { highlightText } from 'utils/string'
import useOutsideClick from 'hooks/useOutsideClick'
import { fetchPhraseInCourseList } from 'services/GlossaryService'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import Tags from 'components/Tags'

import eventEmitter from 'providers/eventEmitter'
import events from 'modules/books/utils/events'

import {
  Input,
  GlossaryIcon,
  BookIcon,
  UnderlinedTabs,
  BouncingLoader,
  HtmlRenderer
} from 'examkrackers-components'
import { GlossaryWord, Course } from 'types'
import { pathOr, propOr } from 'ramda'
import { BOOK_VIEWER_PARAMS } from 'utils/books'

export const SEARCH_GLOSSARY_TABS = {
  glossary: 'glossary',
  book: 'index'
}

export const InputSearchGlossaryWords = ({
  hasCustomLogo
}: {
  hasCustomLogo: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const containerRef = React.useRef(null)
  const [value, setValue] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState(
    SEARCH_GLOSSARY_TABS.glossary
  )
  const [bookPhrases, setBookPhrases] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const { push } = useHistory()

  const isGlossaryLoading = useSelector(getIsGlossaryLoading)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const handleClose = () => {
    setIsOpen(false)
    setCurrentTab(SEARCH_GLOSSARY_TABS.glossary)
  }
  const handleOpen = () => setIsOpen(true)

  const dispatch = useDispatch()

  const searchGlossaryPhrases = React.useCallback(
    value =>
      dispatch(
        fetchGlossaryWordsListRoutine({
          query: `?${qs.stringify({
            order: { by: 'phrase_raw', dir: SORT_DIRECTION.asc },
            limit: { take: 100, page: 1 },
            filter: { search: value }
          })}`
        })
      ),
    [dispatch]
  )

  const searchBookPhrases = value => {
    setIsLoading(true)
    const handleSuccess = response => {
      const phrases = pathOr([], ['data', 'data'], response)
      setBookPhrases(phrases)
      setIsLoading(false)
    }
    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }

    fetchPhraseInCourseList({
      id: currentCourse.id,
      search: value
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  const resetGlossaryPhrases = React.useCallback(
    () => dispatch(resetGlossaryWordsListRoutine()),
    [dispatch]
  )

  const resetPhrases = () => {
    resetGlossaryPhrases()
    setBookPhrases([])
  }

  const handleChange = e => {
    e.preventDefault()
    setValue(e.target.value)
  }
  const handleSearch = value => {
    if (currentTab === SEARCH_GLOSSARY_TABS.glossary) {
      searchGlossaryPhrases(value)
    } else {
      searchBookPhrases(value)
    }
  }
  const debouncedSearch = debounce(handleSearch, 500)

  // @ts-ignore
  const phrases: GlossaryWord[] = useSelector(
    getGlossaryPhrasesByAvailableBooks
  )

  React.useEffect(() => {
    if (isNotNilOrEmpty(value) && value.length >= 3) {
      debouncedSearch(value)
    } else {
      resetPhrases()
    }
  }, [value, currentTab])

  useOutsideClick(containerRef, handleClose)

  const renderGlossaryResults = phrases.map(result => (
    <ResultContainer
      key={`glossary-phrase-result-${R.propOr('', 'id', result)}`}
    >
      <PhraseContainer>
        <div className='phrase'>
          <HtmlRenderer htmlString={result.phrase_html} />
        </div>

        <span>-</span>

        <div className='explanation'>
          <HtmlRenderer htmlString={result.explanation_html} />
        </div>
      </PhraseContainer>
    </ResultContainer>
  ))

  const renderBookResults = bookPhrases.map((result, index) => {
    const chapterOrder = propOr('', 'chapter_order', result)
    const partOrder = propOr('', 'part', result)
    const bookContentId = propOr('', 'student_book_content_id', result)
    const subchapterId = propOr('', 'student_subchapter_id', result)
    const bookOriginalIdFromResponse = propOr('', 'original_book_id', result)
    const tag = propOr('', 'tag', result)

    const handleScrollToBookContent = () => {
      const bookContentAnchor = document.getElementById(
        `book-content-anchor-${bookContentId}`
      )
      bookContentAnchor &&
        bookContentAnchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
    }

    const handleHighlightSearchPhrase = () => {
      eventEmitter.emit(events.highlightSearchPhrase, {
        bookContentId,
        phrase: value
      })
    }

    const redirectToBookContent = () => {
      const link = `/books/${bookOriginalIdFromResponse}/chapter/${chapterOrder}/part/${partOrder}?${BOOK_VIEWER_PARAMS.sectionIdContext}=${subchapterId}&${BOOK_VIEWER_PARAMS.bookContentIdContext}=${bookContentId}`
      push(link)
      setTimeout(handleScrollToBookContent, 3000)
      setTimeout(handleHighlightSearchPhrase, 4000)
    }

    const contentRaw = R.propOr('', 'content_raw', result)
    const resourceRaw = R.propOr('', 'resource_raw', result)

    const hasContentResult = R.propOr(false, 'content_match', result)
    const hasResourceResult = R.propOr(false, 'resource_match', result)

    return (
      <React.Fragment key={`book-phrase-result-${bookContentId}-${index}`}>
        {hasContentResult && (
          <ResultContainer
            key={`book-phrase-result-content-${bookContentId}-${index}`}
          >
            <BookPhraseContainer>
              <div className='index-outer'>
                <div onClick={redirectToBookContent} className='index'>
                  <TagsParent>
                    <Tags tags={[tag]} id={bookContentId} />
                  </TagsParent>
                </div>
              </div>
              <div
                className='phrase'
                dangerouslySetInnerHTML={{
                  __html: highlightText(contentRaw, value)
                }}
              />
            </BookPhraseContainer>
          </ResultContainer>
        )}
        {hasResourceResult && (
          <ResultContainer
            key={`book-phrase-result-resource-${bookContentId}-${index}`}
          >
            <BookPhraseContainer>
              <div className='index-outer'>
                <div onClick={redirectToBookContent} className='index'>
                  <TagsParent>
                    <Tags tags={[tag]} id={bookContentId} />
                  </TagsParent>
                </div>
              </div>
              <div
                className='phrase'
                dangerouslySetInnerHTML={{
                  __html: highlightText(resourceRaw, value)
                }}
              />
            </BookPhraseContainer>
          </ResultContainer>
        )}
      </React.Fragment>
    )
  })

  const tabs = [
    {
      label: (
        <>
          <GlossaryIcon />
          <span>{t('bookViewer.glossary.tabs.glossary')}</span>
        </>
      ),
      value: SEARCH_GLOSSARY_TABS.glossary
    },
    {
      label: (
        <>
          <BookIcon />
          <span>{t('bookViewer.glossary.tabs.book')}</span>
        </>
      ),
      value: SEARCH_GLOSSARY_TABS.book
    }
  ]

  const tabContents = [
    {
      value: SEARCH_GLOSSARY_TABS.glossary,
      content: (
        <>
          {isGlossaryLoading && (
            <LoaderContainer>
              <BouncingLoader />
            </LoaderContainer>
          )}
          {!isGlossaryLoading && isNilOrEmpty(renderGlossaryResults) && (
            <EmptyStateContainer>
              {t('bookViewer.glossary.emptyGlossary')}
            </EmptyStateContainer>
          )}
          {!isGlossaryLoading && isNotNilOrEmpty(phrases) && (
            <ResultsList>{renderGlossaryResults}</ResultsList>
          )}
        </>
      )
    },
    {
      value: SEARCH_GLOSSARY_TABS.book,
      content: (
        <>
          {isLoading && (
            <LoaderContainer>
              <BouncingLoader />
            </LoaderContainer>
          )}
          {!isLoading && isNilOrEmpty(bookPhrases) && (
            <EmptyStateContainer>
              {t('bookViewer.glossary.emptyIndex')}
            </EmptyStateContainer>
          )}
          {!isLoading && isNotNilOrEmpty(bookPhrases) && (
            <ResultsList>{renderBookResults}</ResultsList>
          )}
        </>
      )
    }
  ]

  return (
    <InputContainer
      ref={containerRef}
      hasCustomLogo={hasCustomLogo}
      isOpen={isOpen}
    >
      <InputOverride hasCustomLogo={hasCustomLogo}>
        <Input
          onFocus={handleOpen}
          size='small'
          type='search'
          value={value}
          onChange={handleChange}
          placeholder={
            hasCustomLogo
              ? t('bookViewer.glossary.tabs.glossary')
              : t('bookViewer.glossary.searchPlaceholder')
          }
        />
      </InputOverride>
      {isOpen && (
        <>
          <ResultsContainer>
            <UnderlinedTabs
              onChange={setCurrentTab}
              activeTab={currentTab}
              tabs={tabs}
              tabContents={tabContents}
            />
            <QuickSearchDefinition>
              <div className='title'>{t('bookViewer.glossary.tipTitle')}</div>
              <div>-</div>
              <div className='description'>
                {t('bookViewer.glossary.tipDescription')}
              </div>
            </QuickSearchDefinition>
          </ResultsContainer>
        </>
      )}
    </InputContainer>
  )
}

export default InputSearchGlossaryWords

const InputContainer = styled.div<{ hasCustomLogo: boolean; isOpen: boolean }>`
  position: relative;
  margin-left: ${({ hasCustomLogo }) => (hasCustomLogo ? '-12px' : '0')};
  min-width: 108px;
  width: 108px;

  &:focus-within {
    width: 210px;
    margin-right: -103px;
  }

  transition: all 0.3s ease;
`

const InputOverride = styled.div<{ hasCustomLogo: boolean }>`
  width: 100%;
  & > div {
    margin: 0 !important;
    box-shadow: ${({ theme }) =>
      theme.colors.topNav.totalSaltyBucksAndTimer.boxShadow};
    border: none;
    border-radius: 25px;
    min-height: 40px;
    width: 100%;

    input {
      line-height: 40px;
      width: 100%;
    }
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`

const ResultsContainer = styled.div`
  background: ${props => props.theme.colors.backgrounds.main};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.48) !important;
  border-radius: 6px;
  transition: opacity 700ms ${({ theme }) => theme.transitions.easing.easeInOut};
  z-index: ${({ theme }) => theme.zIndex.menu};
  max-width: 725px !important;
  min-width: 725px !important;
  position: absolute;
  top: 100%;
  right: 0;
`

const ResultContainer = styled.div`
  display: block;
  line-height: normal;
  min-width: 100%;
  width: 100%;
  padding: 16px 16px 0 16px;
  white-space: nowrap;
`

const ResultsList = styled.div`
  overflow-y: auto;
  max-height: 346px;
  min-height: 20px;
`

const PhraseContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  gap: 6px;

  .phrase {
    flex: none;
    width: 100px;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.header};
  }

  .explanation {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.main.text};
  }

  .highlight {
    background: transparent !important;
    font-weight: 700;
  }

  img {
    max-width: 510px !important;
  }
`

const QuickSearchDefinition = styled.div`
  margin: 16px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.topNav.freeTrial};
  border-radius: 6px;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  gap: 6px;
  color: ${({ theme }) => theme.colors.main.primary800};

  .title {
    white-space: nowrap;
    font-weight: 700;
  }

  .description {
    white-space: pre-wrap;
  }
`

const BookPhraseContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  gap: 6px;
  overflow: hidden;

  .index-outer {
    flex: none;
  }

  .index {
    display: inline-block;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.main.header};
    /* padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.colors.main.grey300}; */
    border-radius: 4px;
  }

  .index > * > * {
    margin: 0 2px 0 0 !important;
  }

  .phrase {
    font-size: 14px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.main.text};
  }

  .highlight {
    background: ${({ theme }) => theme.colors.highlightText};
  }
`
const TagsParent = styled.div`
  pointer-events: none;
`
