import React, { useRef, useMemo, useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import {
  CalendarIcon,
  Button,
  SingleSelect,
  BouncingLoader,
  CameraIcon,
  Tooltip
} from 'examkrackers-components'
import {
  calendarEventType,
  eventDetailsDescription,
  checkEventTiming,
  statusOptions
} from 'utils/calendar'

import {
  addDaysToDate,
  formatMinutesToHoursAndMinutes,
  getUniversalDateString,
  formatTime,
  calculateDuration
} from 'utils/date'
import { useTranslation } from 'react-i18next'
import { getOptionByValue } from 'utils/form'
import useOutsideClickWithoutTimeout from 'hooks/useOutsideClickWithoutTimeout'
import {
  updateCalendarEvent,
  getSingleCalendarEvent
} from 'services/CalendarService'
import { isNotNilOrEmpty } from 'utils/ramda'
import ExamButton from 'modules/exams/components/ExamButton'
import * as R from 'ramda'
import { EXAM_STATUS } from 'utils/exam'
import { getExamsList } from 'modules/exams/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { shortenTitle } from 'utils/string'

import RemoveCalendarEventModal from 'modules/calendar/RemoveCalendarEventModal'

import DateInput from 'components/DateInput/DateInput'

import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'
import { CalendarContext } from 'hooks/CalendarContext'
import { removeManualCalendarTaskRoutine } from 'modules/calendar/ducks/actions'
import { isSameDay } from 'date-fns'
import { equals } from 'ramda'

import NextClassesModal from './NextClassesModal'

interface DisplayEventModalProps {
  open: boolean
  isFullLengthExamEvent: boolean
  isSectionExamTypeEvent: boolean
  setUpdateStatusEvent: (value: string) => void
  isReviewEvent: boolean
  handleClose: () => void
  containerPosition: any
  event: any
  hideMenu: () => void
}

const DisplayEventModal: React.FC<DisplayEventModalProps> = ({
  open,
  isFullLengthExamEvent,
  isSectionExamTypeEvent,
  setUpdateStatusEvent,
  isReviewEvent,
  handleClose,
  containerPosition,
  event,
  hideMenu
}) => {
  const { updateEvent } = useContext(CalendarContext)
  const {
    action_uri,
    duration,
    event_colour,
    event_date,
    id,
    title,
    type,
    status,
    student_exam_status
  } = event

  const isArchived = status === 'archived'
  const isLiveClass =
    type === calendarEventType.liveClass ||
    type === calendarEventType.customLiveClass ||
    type === calendarEventType.customEndDateEvent
  const classTime: string = R.propOr('', 'class_time', event)
  const classTimeConverted = classTime && formatTime(classTime)

  const classTimeEnd: string = R.propOr('', 'class_time_end', event)
  const classTimeEndConverted = classTimeEnd && formatTime(classTimeEnd)
  const liveClassDescription = `${classTimeConverted} - ${classTimeEndConverted}pm EST`

  const shouldEvenBeAvailable = checkEventTiming(event)

  const isCustomEndDateEvent = type === calendarEventType.customEndDateEvent
  const hasActionUri = isNotNilOrEmpty(action_uri)

  const history = useHistory()
  const { push } = history
  const defaultOption = getOptionByValue(status)(statusOptions())
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [eventDate, setEventDate] = useState({ event_date: event_date })
  const [isLoading, setIsLoading] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [_, setClose] = useState(false)
  const { t } = useTranslation()
  const modalRef = useRef<HTMLDivElement>(null!)
  const initialRender = useRef(true)
  const studentDetails = useSelector(getStudent)
  const examList = useSelector(getExamsList)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedOption(getOptionByValue(status)(statusOptions()))
  }, [status])

  const examId = event.student_item_id
  const examIds = event.student_exam_ids || ''
  const currentCourse: Course = useSelector(getCurrentCourse)

  const courseEndDate = useMemo(() => {
    const accessibleTo = R.propOr('', 'accessible_to', currentCourse)

    const daysAmount = R.pipe(
      R.propOr('{}', 'original_metadata'),
      JSON.parse,
      R.propOr('0', 'days_amount'),
      Number
    )(currentCourse)

    const calculatedAccessibleTo = addDaysToDate(new Date(), daysAmount)

    return isNotNilOrEmpty(accessibleTo)
      ? new Date(accessibleTo as Date)
      : calculatedAccessibleTo
  }, [currentCourse])

  const exam =
    examList.find(exam => examIds.includes(exam.id)) ||
    examList.find(exam => exam.id === examId)

  const isOtherExam = type === calendarEventType.otherExam
  const isOtherExamReview = type === calendarEventType.otherExamReview

  const hasVideoReview = isReviewEvent && isNotNilOrEmpty(action_uri)
  const isExamCompleted = isReviewEvent && student_exam_status === 'completed'
  const isChapterExamReview = event.type === calendarEventType.chapterExamReview
  const color = event_colour

  const top = containerPosition?.top || 0
  const varibleTopDimension =
    top < 300
      ? top + 24
      : isReviewEvent && !hasVideoReview
      ? top - 240
      : top - 210
  const left = containerPosition?.left || 0

  const handleValueChange = (name, value) => {
    setEventDate(prev => ({ ...prev, [name]: value }))
  }

  const handleEventUpdate = async selectedOption => {
    setIsLoading(true)
    try {
      const response = await updateCalendarEvent({
        id,
        status: selectedOption.value,
        event_date: eventDate.event_date
      })

      if ([200, 201].includes(response.status)) {
        updateEvent({ oldEvent: event, newEvent: response.data })
        setUpdateStatusEvent(response.data.status)
      }
      setIsLoading(false)
      getSingleCalendarEvent({ id: id as string })
    } catch (error) {
      console.error('error', error)
    }
    setIsLoading(false)
  }

  const handleCloseModal = async () => {
    await setClose(true)
    setEventDate(prev => ({ ...prev, event_date: event.event_date }))
    handleClose()
  }

  const handleArchivedEventDateChange = async (_, date) => {
    setEventDate(prev => ({ ...prev, event_date: date }))
  }

  const redirectToSelectItem = () => {
    if (isLiveClass) {
      window.open(action_uri, '_blank')
    } else {
      push(action_uri)
    }
  }

  useOutsideClickWithoutTimeout(modalRef, handleCloseModal)

  const handleSave = async () => {
    if (isArchived) {
      try {
        setIsLoading(true)
        const response = await updateCalendarEvent({
          id,
          status: 'incomplete',
          event_date: eventDate.event_date
        })

        if ([200, 201].includes(response.status)) {
          updateEvent({ oldEvent: event, newEvent: response.data })
          setUpdateStatusEvent(response.data.status)
        }
        setIsLoading(false)
        getSingleCalendarEvent({ id: id as string })
        dispatch(
          removeManualCalendarTaskRoutine({ id: id, type: response.data.type })
        )
        handleClose()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)

        console.error('error', error)
      }
    } else {
      await handleEventUpdate(selectedOption)
      setUpdateStatusEvent(selectedOption.value)
    }
    handleCloseModal()
  }

  const isHalfLengthExamEvent = title => title === 'Half Lenght Exam'

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    setSaveDisabled(false)
  }, [selectedOption, eventDate])

  useEffect(() => {
    setEventDate({ event_date: event_date })
  }, [event])

  const renderButton = () => {
    if (
      exam &&
      (type === calendarEventType.chapterExam ||
        type === calendarEventType.sectionExam ||
        type === calendarEventType.customSectionExam ||
        type === calendarEventType.fullLengthExam ||
        type === calendarEventType.customFullLengthExam ||
        type === calendarEventType.otherExam)
    ) {
      return (
        <StyledExamButton
          data-testid='DisplayEventModalProps-StyledExamButton'
          status={R.propOr(EXAM_STATUS.scheduled, 'status', exam)}
          id={R.propOr('', 'id', exam)}
          date={R.propOr('', 'accessible_to', exam)}
          exam={exam}
          isImpersonated={R.propOr(false, 'is_impersonated', studentDetails)}
          isCalendarPage
          disabled={isArchived}
        />
      )
    } else if (
      type === calendarEventType.chapterExamReview ||
      type === calendarEventType.sectionExamReview ||
      type === calendarEventType.fullLengthExamReview ||
      type === calendarEventType.otherExamReview
    ) {
      return (
        <>
          {!isExamCompleted ? (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip-Button-beginReview'
              tooltipContent={t('calendar.button.tooltipExamReview')}
              id='beginReviewTooltip'
            >
              <Button
                data-testid='DisplayEventModalProps-Button-beginReview'
                variant='contained'
                color='secondary'
                size='small'
                onClick={redirectToSelectItem}
                disabled={!hasVideoReview || isArchived || !isExamCompleted}
              >
                {t('calendar.button.beginReview')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              data-testid='DisplayEventModalProps-Button-beginReview'
              variant='contained'
              color='secondary'
              size='small'
              onClick={redirectToSelectItem}
              disabled={!hasVideoReview || isArchived || !isExamCompleted}
            >
              {t('calendar.button.beginReview')}
            </Button>
          )}
        </>
      )
    } else if (type === calendarEventType.fullLengthExamReview) {
      return (
        <>
          {!isExamCompleted ? (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip-Button-beginReview'
              tooltipContent={t('calendar.button.tooltipExamReview')}
              id='beginReviewTooltip'
            >
              <Button
                data-testid='DisplayEventModalProps-Button-beginReview-fullLengthExamReview'
                variant='contained'
                color='secondary'
                size='small'
                onClick={redirectToSelectItem}
                disabled={!hasVideoReview || isArchived || !isExamCompleted}
              >
                {t('calendar.button.beginReview')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              data-testid='DisplayEventModalProps-Button-beginReview-fullLengthExamReview'
              variant='contained'
              color='secondary'
              size='small'
              onClick={redirectToSelectItem}
              disabled={!hasVideoReview || isArchived || !isExamCompleted}
            >
              {t('calendar.button.beginReview')}
            </Button>
          )}
        </>
      )
    } else if (
      type === calendarEventType.liveClass ||
      type === calendarEventType.customLiveClass ||
      isCustomEndDateEvent
    ) {
      return (
        <>
          {shouldEvenBeAvailable && hasActionUri ? (
            <Button
              data-testid='DisplayEventModalProps-Button-shouldEvenBeAvailable'
              variant='contained'
              color='secondary'
              size='small'
              onClick={redirectToSelectItem}
              disabled={!shouldEvenBeAvailable}
            >
              <ButtonClassWrapper data-testid='DisplayEventModalProps-ButtonClassWrapper'>
                <CameraIcon data-testid='DisplayEventModalProps-CameraIcon' />
                {t('calendar.button.joinSession')}
              </ButtonClassWrapper>
            </Button>
          ) : (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip'
              tooltipContent={t('calendar.liveClassTooltip')}
              id='liveClassTooltip'
            >
              <Button
                data-testid='DisplayEventModalProps-disabledButton'
                variant='contained'
                color='secondary'
                size='small'
                disabled
              >
                <ButtonClassWrapper>
                  <CameraIcon />
                  {t('calendar.button.unavailable')}
                </ButtonClassWrapper>
              </Button>
            </Tooltip>
          )}
        </>
      )
    } else if (
      type === calendarEventType.customFullLengthExamReview ||
      type === calendarEventType.customSectionExamReview ||
      type === calendarEventType.otherExamReview
    ) {
      return (
        <>
          {!isExamCompleted ? (
            <Tooltip
              data-testid='DisplayEventModalProps-Tooltip-Button-beginReview'
              tooltipContent={t('calendar.button.tooltipExamReview')}
              id='beginReviewTooltip'
            >
              <Button
                data-testid='DisplayEventModalProps-Button-beginReview-case1'
                variant='contained'
                color='secondary'
                size='small'
                onClick={redirectToSelectItem}
                disabled={!hasVideoReview || isArchived || !isExamCompleted}
              >
                {t('calendar.button.beginReview')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              data-testid='DisplayEventModalProps-Button-beginReview-case1'
              variant='contained'
              color='secondary'
              size='small'
              onClick={redirectToSelectItem}
              disabled={!hasVideoReview || isArchived || !isExamCompleted}
            >
              {t('calendar.button.beginReview')}
            </Button>
          )}
        </>
      )
    } else {
      return (
        <Button
          data-testid='DisplayEventModalProps-Button-begin'
          variant='contained'
          color='secondary'
          size='small'
          onClick={redirectToSelectItem}
          disabled={isArchived}
        >
          {type === calendarEventType.chapter ||
          type === calendarEventType.bookLinkRreReading
            ? t('calendar.button.beginChapter')
            : t('calendar.button.begin')}
        </Button>
      )
    }
  }

  const handleChange = (e: any) => {
    setSelectedOption(e)
  }

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  const hasChanges =
    !equals(selectedOption, defaultOption) ||
    !equals(event_date, eventDate.event_date)

  const isCustomLiveClassType = event.type === 'custom_live_class'

  return (
    <EventCard
      data-testid='DisplayEventModalProps-EvenCard'
      open={open}
      top={varibleTopDimension}
      left={left}
      ref={modalRef}
      isChapterExamReview={isChapterExamReview}
    >
      <Container data-testid='DisplayEventModalProps-Container'>
        <>
          <TopWrapper data-testid='DisplayEventModalProps-TopWrapper'>
            {isCustomEndDateEvent ? (
              <CustomEndDateEventTitle>{title}</CustomEndDateEventTitle>
            ) : (
              <Tag
                data-testid='DisplayEventModalProps-Tag'
                isLiveClass={isLiveClass || isCustomEndDateEvent}
                color={color}
                isFullLengthExamEvent={isFullLengthExamEvent}
                isSectionExamTypeEvent={isSectionExamTypeEvent}
                isOtherExamTypeEvent={isOtherExam || isOtherExamReview}
              >
                {isFullLengthExamEvent ||
                isSectionExamTypeEvent ||
                isOtherExam ||
                isOtherExamReview
                  ? title
                  : shortenTitle(title as string)}
              </Tag>
            )}
            <StatusWrapper data-testid='DisplayEventModalProps-StatusWrapper'>
              <ScheduleWrapper data-testid='DisplayEventModalProps-ScheduleWrapper'>
                {t('calendar.scheduled')}:{' '}
              </ScheduleWrapper>
              <DateInputWrapper data-testid='DisplayEventModalProps-DateInputWrapper'>
                <PickerWrapper data-testid='DisplayEventModalProps-PickerWrapper'>
                  <DateInput
                    data-testid='DisplayEventModalProps-DateInput-event_date'
                    name='event_date'
                    id={`regular_event_event_date-${id}`}
                    popperPlacement='right-start'
                    value={
                      isArchived
                        ? isSameDay(
                            new Date(eventDate.event_date),
                            new Date(event.event_date)
                          )
                          ? ''
                          : getUniversalDateString(eventDate.event_date)
                        : getUniversalDateString(eventDate.event_date)
                    }
                    validate={() => true}
                    size='small'
                    onChange={
                      isArchived
                        ? handleArchivedEventDateChange
                        : handleValueChange
                    }
                    minDate={new Date()}
                    maxDate={courseEndDate}
                    disabled={isLiveClass || isCustomEndDateEvent}
                  />
                </PickerWrapper>
              </DateInputWrapper>
              <IconContainer>
                <CalendarIcon
                  data-testid='DisplayEventModalProps-CalendarIcon-regular_event_event_date'
                  onClick={handleCalendarManualOpen(
                    `regular_event_event_date-${id}`
                  )}
                />

                {(type === calendarEventType.customFullLengthExamReview ||
                  type === calendarEventType.customSectionExamReview) && (
                  <RemoveCalendarEventModal
                    data-testid='DisplayEventModalProps-RemoveCalendarEventModal-case1'
                    event={event}
                    onSelect={hideMenu}
                    isArchived={isArchived}
                  />
                )}
                {(type === calendarEventType.customFullLengthExam ||
                  type === calendarEventType.customSectionExam) && (
                  <RemoveCalendarEventModal
                    data-testid='DisplayEventModalProps-RemoveCalendarEventModal-case2'
                    event={event}
                    onSelect={hideMenu}
                    isArchived={isArchived}
                  />
                )}
              </IconContainer>
            </StatusWrapper>
          </TopWrapper>
          <BorderContainer />
          {/* Regular event description */}
          {!isReviewEvent && !isLiveClass && (
            <Description>
              <p style={{ flexGrow: 1 }}>
                {isHalfLengthExamEvent(title)
                  ? 'Half-Length MCAT'
                  : eventDetailsDescription(type)}
              </p>
              {!isCustomEndDateEvent && (
                <p style={{ flexGrow: 1 }}>
                  {t('calendar.duration')}:{' '}
                  {formatMinutesToHoursAndMinutes(duration)}
                </p>
              )}
            </Description>
          )}

          {/* Review event without video */}
          {!hasVideoReview && !isLiveClass && isReviewEvent && (
            <>
              <p>{eventDetailsDescription(type)}</p>
              <br />
              <p>{t('calendar.videoReviewUnavailable')}</p>
            </>
          )}

          {/* Review event with video */}
          {hasVideoReview && !isLiveClass && (
            <Description>
              <p style={{ flexGrow: 1 }}>{eventDetailsDescription(type)}</p>
              <p style={{ flexGrow: 1 }}>
                {t('calendar.duration')}:{' '}
                {formatMinutesToHoursAndMinutes(duration)}
              </p>
            </Description>
          )}

          {/* Live class description */}
          {(isLiveClass || isCustomEndDateEvent) && (
            <DescriptionLiveClass data-testid='DisplayEventModalProps-DescriptionLiveClass'>
              <ContainerLiveClass>
                <ClassType data-testid='DisplayEventModalProps-ClassType-Class'>
                  Class
                </ClassType>
                <ClassTime data-testid='DisplayEventModalProps-ClassTime'>
                  {liveClassDescription}
                </ClassTime>
                <ClassDuration data-testid='DisplayEventModalProps-ClassDuration'>
                  <p style={{ flexGrow: 1 }}>
                    {t('calendar.duration')}:{' '}
                    {formatMinutesToHoursAndMinutes(
                      calculateDuration(classTime, classTimeEnd)
                    )}
                  </p>
                </ClassDuration>
              </ContainerLiveClass>
            </DescriptionLiveClass>
          )}
          <BottomWrapper data-testid='DisplayEventModalProps-BottomWrapper'>
            {isLiveClass || isCustomEndDateEvent ? null : (
              <SelectWrapper data-testid='DisplayEventModalProps-SelectWrapper'>
                <SingleSelect
                  data-testid='DisplayEventModalProps-SingleSelect'
                  removeMargin
                  id={`${id}-select-status`}
                  label={isArchived ? '' : t('calendar.eventCard.status')}
                  size='small'
                  options={statusOptions()}
                  onChange={handleChange}
                  value={selectedOption}
                  isSearchable={false}
                  disabled={isArchived}
                />
              </SelectWrapper>
            )}
          </BottomWrapper>
          {isLoading ? (
            <LoaderContainer data-testid='DisplayEventModalProps-LoaderContainer'>
              <BouncingLoader data-testid='DisplayEventModalProps-BouncingLoader' />
            </LoaderContainer>
          ) : (
            <ButtonsWrapper data-testid='DisplayEventModalProps-ButtonWrapper'>
              <Button
                data-testid='DisplayEventModalProps-Button-cancel'
                variant='contained'
                color='tertiary'
                size='small'
                onClick={handleCloseModal}
              >
                {t('calendar.button.cancel')}
              </Button>
              {isLiveClass || isCustomEndDateEvent ? null : (
                <Button
                  data-testid='DisplayEventModalProps-Button-save'
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={handleSave}
                  disabled={saveDisabled || !hasChanges}
                >
                  {t('calendar.button.save')}
                </Button>
              )}
              {renderButton()}
            </ButtonsWrapper>
          )}

          <ExtraClassesWrapper isCustomLiveClassType={isCustomLiveClassType}>
            {isLiveClass && <NextClassesModal event={event} title={title} />}
            {isCustomLiveClassType && (
              <RemoveCalendarEventModal
                event={event}
                onSelect={hideMenu}
                data-testid='DisplayCustomEventModal-RemoveCalendarEventModal'
                isCustomLiveClassType={isCustomLiveClassType}
              />
            )}
          </ExtraClassesWrapper>
        </>
      </Container>
      <ArrowIconWrapper top={top} />
    </EventCard>
  )
}

export default DisplayEventModal

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 52px;
`

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`
const ArrowIconWrapper = styled.div`
  position: absolute;

  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 15px solid white;
  ${({ top }) =>
    top < 300
      ? css`
          top: -24px;
          transform: rotate(180deg);
        `
      : 'bottom: -24px;'}
`

const Tag = styled.div<{
  color: string
  isSectionExamTypeEvent: boolean
  isFullLengthExamEvent: boolean
  isLiveClass: boolean
}>`
  font-weight: bold;
  font-size: 14px;
  padding: 6px;
  background: ${({ color, theme, isLiveClass }) =>
    isLiveClass
      ? theme.colors.calendar[color]?.class
      : theme.colors.calendar[color]?.background ||
        theme?.colors?.calendar.exam.orange.background ||
        theme.colors.main.grey100};
  border-radius: 4px;
  border: ${({ isSectionExamTypeEvent, isFullLengthExamEvent, theme }) =>
    (isSectionExamTypeEvent || isFullLengthExamEvent) &&
    `1px solid ${theme?.colors?.calendar.exam.orange.tag}`};
  color: ${({
    isSectionExamTypeEvent,
    isFullLengthExamEvent,
    isOtherExamTypeEvent,
    color,
    theme,
    isLiveClass
  }) =>
    isFullLengthExamEvent || isSectionExamTypeEvent || isOtherExamTypeEvent
      ? theme.colors.calendar.exam.orange.tag
      : isLiveClass
      ? theme.colors.main.white
      : theme.colors.calendar[color]?.tag || theme.colors.main.black};
`

const EventCard = styled.div<{
  open: boolean
  left: number
  top: number
  isChapterExamReview: boolean
}>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  // TODO: calculate properly not on numbers from thin air
  left: ${({ left }) => left - 105}px;
  top: ${({ top }) => top}px;
  width: 345px;
  color: ${({ theme }) => theme.colors.main.grey900};
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.2);
  z-index: 999;
`
const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary400};
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BorderContainer = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main.grey300};
`
const Description = styled.div`
  display: flex;
  gap: 8px;
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 220px;
`
const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  position: relative;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;
`

const StyledExamButton = styled(ExamButton)`
  /* width: 100%; */
  background: linear-gradient(180deg, #f3e2d7 0%, #d7bcaa 100%) !important;
`
const PickerWrapper = styled.div`
  display: flex;
  width: 90px;
  position: absolute;
  right: 30px;
  top: -30px;
  margin-right: 15px;
`
const DateInputWrapper = styled.div`
  width: 60px;
`
const ScheduleWrapper = styled.div``

const ContainerLiveClass = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const ClassType = styled.div`
  display: flex;
`
const ClassTime = styled.div`
  font-weight: bold;
  display: flex;
`

const ClassDuration = styled.div`
  display: flex;
`

const DescriptionLiveClass = styled.div`
  display: flex;
  width: 300px;
  margin-top: 8px;
`
const ButtonClassWrapper = styled.div`
  display: flex;
  gap: 10px;
`
const ExtraClassesWrapper = styled.div<{ isCustomLiveClassType: boolean }>`
  display: flex;
  justify-content: ${({ isCustomLiveClassType }) =>
    isCustomLiveClassType ? 'space-between' : 'center'};
  margin-top: 10px;
`
const CustomEndDateEventTitle = styled.div`
  font-weight: bold;
  max-width: 90px;
`
