import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty, pathOr, propOr } from 'ramda'
import { BOOK_VIEWER_PARAMS, QUESTION_FILTERS } from 'utils/books'
import { useHistory, useParams } from 'react-router-dom'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'types'
import {
  getBookContentQuestionsBySubchapterId,
  getIsContentQuestionsLoading
} from 'modules/books/ducks/selectors'
import { refreshBookFragmentDataRoutine } from 'modules/books/ducks/actions'

import { getIsPreviewAdmin } from 'modules/auth/ducks/selectors'
import ContentQuestionAnimationHandler from './ContentQuestionAnimationHandler'
import * as R from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import FilterContentQuestions from './FilterContentQuestions'
import { useTranslation } from 'react-i18next'
import { SuspenseFadeIn } from 'examkrackers-components'

const ContentQuestion = React.lazy(
  () => import('modules/books/components/ContentQuestion')
)
const ContentQuestionForAdmin = React.lazy(
  () => import('modules/books/components/ContentQuestionForAdmin')
)

export const ContentQuestionsPreview = ({ subchapterNumber }): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentSlide, setCurrentSlide] = useState(0)
  const {
    location: { search }
  } = useHistory()
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const params = useParams()

  const chapterOrder: string = propOr('1', 'chapterOrder', params)
  const bookId: string = propOr('', 'bookId', params)
  const partOrder: string = propOr('', 'partOrder', params)

  const subchapterId = propOr(
    '',
    BOOK_VIEWER_PARAMS.sectionIdContext
  )(parsedQuery)

  const selectedFilter: string = R.propOr(
    QUESTION_FILTERS.all,
    BOOK_VIEWER_PARAMS.contentQuestionFilter
  )(parsedQuery)

  const allQuestions = useSelector((state: RootReducer) =>
    getBookContentQuestionsBySubchapterId(state, subchapterId)
  )

  const refreshContentQuestions = React.useCallback(
    () =>
      dispatch(
        refreshBookFragmentDataRoutine({
          params: { bookId, chapterOrder, partOrder },
          fragmentName: 'content_questions'
        })
      ),
    [dispatch]
  )

  const isLoading = useSelector(getIsContentQuestionsLoading)

  const answeredQuestions = R.filter(
    R.pipe(R.propOr(null, 'answered_at'), isNotNilOrEmpty)
  )(allQuestions)

  const unansweredQuestions = R.filter(R.propEq('answered_at', null))(
    allQuestions
  )

  const questionsVariants = {
    [QUESTION_FILTERS.all]: allQuestions,
    [QUESTION_FILTERS.answered]: answeredQuestions,
    [QUESTION_FILTERS.unanswered]: unansweredQuestions
  }

  const contentQuestions = questionsVariants[selectedFilter]
  const currentQuestion = pathOr({}, [currentSlide], contentQuestions)
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)

  useEffect(() => {
    setCurrentSlide(0)
  }, [subchapterId])

  useEffect(() => {
    setCurrentSlide(0)
    refreshContentQuestions()
  }, [selectedFilter])

  useEffect(() => {
    if (isNilOrEmpty(currentQuestion)) {
      setCurrentSlide(contentQuestions.length - 1)
    }
  }, [contentQuestions])

  return (
    <ContentWrapper data-testid='ContentQuestionsPreview-content-wrapper'>
      <SuspenseFadeIn>
        <>
          <ContentQuestionAnimationHandler data-testid='ContentQuestionsPreview-animation-handler' />
          {isEmpty(contentQuestions) && !isLoading && (
            <EmptyState data-testid='ContentQuestionsPreview-empty-state'>
              <FilterContentQuestions data-testid='ContentQuestionsPreview-filter-content-questions' />
              {t(`bookViewer.contentQuestions.emptyState.${selectedFilter}`, {
                section: subchapterNumber
              })}
            </EmptyState>
          )}
          {!isEmpty(contentQuestions) &&
            !isLoading &&
            (isPreviewAdmin ? (
              <ContentQuestionForAdmin
                data-testid='ContentQuestionsPreview-content-question-for-admin'
                key={`question-${pathOr(
                  '',
                  [currentSlide, 'id'],
                  contentQuestions
                )}`}
                question={currentQuestion}
                questionNumber={currentSlide + 1}
                setCurrentSlide={setCurrentSlide}
                questionsCount={contentQuestions.length}
              />
            ) : (
              <ContentQuestion
                data-testid='ContentQuestionsPreview-content-question'
                key={`question-${pathOr(
                  '',
                  [currentSlide, 'id'],
                  contentQuestions
                )}`}
                question={currentQuestion}
                questionNumber={currentSlide + 1}
                setCurrentSlide={setCurrentSlide}
                questionsCount={contentQuestions.length}
                currentQuestion={currentQuestion}
              />
            ))}
        </>
      </SuspenseFadeIn>
    </ContentWrapper>
  )
}

export default ContentQuestionsPreview

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
`

const EmptyState = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`
