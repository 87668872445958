import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'

import {
  getFlashcardsSelectedSide,
  isFlashcardsLoading
} from 'modules/flashcards/ducks/selectors'
import {
  getBookTagById,
  selectFreeTrialAvailableBookTags,
  getBookByOriginalId,
  getChapterByOrder
} from 'modules/books/ducks/selectors'
import {
  getCourseFlashcardsCount,
  getCurrentCourse
} from 'modules/courses/ducks/selectors'

import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'
import { updateFlashcardManuallyRoutine } from 'modules/books/ducks/actions'
import { updatePlvlStatsManuallyRoutine } from 'modules/flashcards/ducks/actions'

import { FLASHCARD_SIDE } from 'utils/flashcards'
import { isElementAvailable } from 'utils/freeTrial'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { isValidDeltaObject } from 'utils/wysiwyg'
import { COURSE_LEARNING_TIME_STATUS, COURSE_TYPES } from 'utils/courses'
// import { EK_PURCHASE_PORTAL_PAGE } from 'utils/links'
import events from 'modules/flashcards/utils/events'
import globalEvents from 'utils/events'
import {
  getStudent,
  isAuthLoaded,
  getIsPreviewAdmin,
  getIsOrdinaryStudent
} from 'modules/auth/ducks/selectors'

import { answerFlashcard } from 'services/FlashcardsService'
import eventEmitter from 'providers/eventEmitter'

import {
  Button,
  NavArrowRightIcon,
  NavArrowLeftIcon,
  ReloadIcon,
  CheckmarkContainedIcon,
  CloseContainedIcon,
  HtmlRenderer,
  Tooltip,
  BouncingLoader,
  AddToArchiveIcon,
  AddToBoxIcon
} from 'examkrackers-components'
import ModalAddCardToArchive from './ModalAddCardToArchive'
import Image from 'components/Image'
import FlashcardsLearningTime from 'modules/learningTime/components/FlashcardsLearningTime'

import { Course } from 'types'
import { getLearningTimeDetails } from '../../learningTime/ducks/selectors'
import { setCourseFlashcardsCount } from '../../../services/CourseService'
import { fetchCurrentCourseRoutine } from '../../courses/ducks/actions'
import ModalAddToCustomBox from 'modules/flashcards/components/ModalAddToCustomBox'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
interface FlashcardAnswerProps {
  size: 'small' | 'large'
  handleNext: () => void
  afterNext?: () => void
  onSuccessAnswer: (data) => void
  handlePrevious: () => void
  flashcard: any
  disablePrevious: boolean
  disableNext: boolean
  isABookView?: boolean
  disableActions?: boolean
  trackFlashcardsLearningTime?: boolean
  numberTotal?: number
  numberCurrent?: number
  disableFlip?: boolean
  side?: string
}

export const FlashcardAnswer = ({
  size,
  flashcard,
  handleNext,
  onSuccessAnswer,
  disableNext,
  disablePrevious,
  handlePrevious,
  isABookView,
  numberTotal,
  numberCurrent,
  disableActions,
  trackFlashcardsLearningTime,
  afterNext,
  disableFlip,
  side
}: FlashcardAnswerProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isFlipped, setIsFlipped] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flipping, setFlipping] = useState(false)
  const flashcardSideToDisplay = side || useSelector(getFlashcardsSelectedSide)
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)
  const availableBookTags = useSelector(selectFreeTrialAvailableBookTags)
  const flashcardId = R.propOr('', 'id', flashcard)
  const isFlashcard = isNotNilOrEmpty(flashcardId)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isFlashcardStateLoading: boolean = useSelector(isFlashcardsLoading)
  const learningTimeDetails = useSelector(getLearningTimeDetails)
  const user = useSelector(getStudent)
  const isAuthFetched = useSelector(isAuthLoaded)
  const isOrdinaryStudent = useSelector(getIsOrdinaryStudent)
  const disableAnswer = isAuthFetched && !isOrdinaryStudent
  const courseFlashcardsCount = useSelector(getCourseFlashcardsCount)
  const params = useParams()

  const fetchCurrentCourse = React.useCallback(
    () => dispatch(fetchCurrentCourseRoutine()),
    [dispatch]
  )

  const updateCourseFlashcardsCount = () => {
    const handleSuccess = () => fetchCurrentCourse()
    const handleError = e => console.error(e)

    setCourseFlashcardsCount({
      flashcard_count: courseFlashcardsCount + 1
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  const learningTimeState: string = R.propOr(
    COURSE_LEARNING_TIME_STATUS.started,
    'state',
    learningTimeDetails
  )

  const isPaused = learningTimeState === COURSE_LEARNING_TIME_STATUS.paused

  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  useEffect(() => {
    setIsLoading(isFlashcardStateLoading)
  }, [isFlashcardStateLoading])

  const handleIsLoading = loading => setIsLoading(loading)

  useEffect(() => {
    eventEmitter.on(events.loadingFlashcardAnswer, handleIsLoading)

    return () => {
      eventEmitter.off(events.loadingFlashcardAnswer, handleIsLoading)
    }
  }, [])

  const bookId: string = propOr('', 'bookId', params)
  const book = useSelector(state => getBookByOriginalId(state, bookId))

  const chapterOrder: string = propOr('1', 'chapterOrder', params)
  const chapter = useSelector(state =>
    getChapterByOrder(state, Number(chapterOrder))
  )

  const flashcardBookId = R.propOr('', 'book_id', flashcard)
  const flashcardBookTag = useSelector(state =>
    getBookTagById(state, flashcardBookId)
  )
  const isFlashcardBookTagAvailable = R.includes(
    flashcardBookTag,
    availableBookTags
  )

  const isAvailable = isABookView
    ? true
    : // I added the second condition because we replaced the endpoint
      // in the flashcards study view, which does not return the list
      // of tags for the card, but only its book id
      isElementAvailable(isFreeTrial, flashcard, availableBookTags) &&
      isFlashcardBookTagAvailable

  const fetchStudentDetails = useCallback(() => {
    dispatch(studentFetchDetailsRoutine())
  }, [dispatch])

  const updateFlashcardManually = useCallback(
    flashcard => {
      dispatch(updateFlashcardManuallyRoutine(flashcard))
    },
    [dispatch]
  )

  const updatePlvlStatsManually = useCallback(
    (from, to) => {
      dispatch(updatePlvlStatsManuallyRoutine({ from, to }))
    },
    [dispatch]
  )

  const handleFlip = () => {
    if (!disableFlip) {
      setFlipping(true)
      setTimeout(() => setIsFlipped(prevState => !prevState), 200)
    }

    if (book?.tag) {
      // @sieradz - flip flashcard in the book
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardFlipped, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
        'Flashcard Book tag': book?.tag || false,
        'Flashcard box tag': `${book?.tag}_${chapter.order}`,
        'Flashcard # id': flashcard?.id || false
      })
    } else {
      // @sieradz - flip flashcard on flashcard page
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardFlipped, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
        'Flashcard # id': flashcard?.id || false,
        Page: 'Flashcard page'
      })
    }
  }

  useEffect(() => {
    setIsFlipped(false)
  }, [flashcard])

  useEffect(() => {
    setTimeout(() => setFlipping(false), 500)
  }, [isFlipped])

  const id: string = propOr('', 'id', flashcard)
  const studentBookContentFlashcardId: string = propOr(
    '',
    'student_flashcard_id',
    flashcard
  )

  const originalId: string = propOr('', 'original_flashcard_id', flashcard)
  const question: string = propOr('{}', 'question', flashcard)
  const questionHtml: string = propOr('', 'question_html', flashcard)
  const questionDeltaObject = JSON.parse(question)
  const questionImage = propOr('', 'question_image', flashcard)
  const explanation: string = propOr('{}', 'explanation', flashcard)
  const explanationHtml: string = propOr('', 'explanation_html', flashcard)
  const explanationDeltaObject = JSON.parse(explanation)
  const explanationImage = propOr('', 'explanation_image', flashcard)
  const proficiencyLevel = propOr('1', 'proficiency_level', flashcard)

  const [textFrontHeight, setTextFrontHeight] = useState(0)
  const [textBackHeight, setTextBackHeight] = useState(0)

  const saveTextDimensions = () => {
    const frontTextContainer = document.querySelector('#content-text-front')
    const backTextContainer = document.querySelector('#content-text-back')

    const frontTextDimensions = frontTextContainer
      ? frontTextContainer.getBoundingClientRect()
      : { height: 0 }
    const backTextDimensions = backTextContainer
      ? backTextContainer.getBoundingClientRect()
      : { height: 0 }

    setTextFrontHeight(frontTextDimensions.height)
    setTextBackHeight(backTextDimensions.height)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(flashcard)) {
      setTimeout(saveTextDimensions, 500)
    }
  }, [flashcard, textBackHeight, textFrontHeight])

  const beforeLabel =
    flashcardSideToDisplay === FLASHCARD_SIDE.front
      ? t('flashcards.filters.front')
      : t('flashcards.filters.back')
  const afterLabel =
    flashcardSideToDisplay === FLASHCARD_SIDE.front
      ? t('flashcards.filters.back')
      : t('flashcards.filters.front')

  const amplitiudeFlashcardAnswer = isCorrect => {
    if (book?.tag) {
      // @sieradz - flip flashcard in the book
      if (isCorrect) {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.flashcardMarkedAsCorrect,
          {
            'Course type': currentCourse.type || false,
            'Course name': currentCourse.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard Proficiency Level':
              flashcard?.proficiency_level || false,
            'Flashcard Book tag': book?.tag || false,
            'Flashcard box tag': `${book?.tag}_${chapter.order}` || false,
            'Flashcard # id': flashcard?.id || false
          }
        )
      } else {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.flashcardMarkedAsIncorrect,
          {
            'Course type': currentCourse.type || false,
            'Course name': currentCourse.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard Proficiency Level':
              flashcard?.proficiency_level || false,
            'Flashcard Book tag': book?.tag || false,
            'Flashcard box tag': `${book?.tag}_${chapter.order}` || false,
            'Flashcard # id': flashcard?.id || false
          }
        )
      }
    } else {
      // @sieradz - flip flashcard on flashcard page
      if (isCorrect) {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.flashcardMarkedAsCorrect,
          {
            'Course type': currentCourse.type || false,
            'Course name': currentCourse.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard Proficiency Level':
              flashcard?.proficiency_level || false,
            'Flashcard Book tag': flashcardBookTag || false,
            'Flashcard box tag':
              `${flashcardBookTag}_${flashcard.chapter_order}` || false,
            'Flashcard # id': flashcard?.id || false
          }
        )
      } else {
        AnalyticsService(user).logEvent(
          ANALYTICS_EVENTS.flashcardMarkedAsIncorrect,
          {
            'Course type': currentCourse.type || false,
            'Course name': currentCourse.title || false,
            'Course expiration date': currentCourse?.accessible_to || false,
            'Flashcard Proficiency Level':
              flashcard?.proficiency_level || false,
            'Flashcard Book tag': flashcardBookTag || false,
            'Flashcard box tag':
              `${flashcardBookTag}_${flashcard.chapter_order}` || false,
            'Flashcard # id': flashcard?.id || false
          }
        )
      }
    }
  }

  const handleAnswerFlashcard = isCorrect => () => {
    setIsLoading(true)
    amplitiudeFlashcardAnswer(isCorrect)

    const handleSuccess = response => {
      const data: {
        has_changed: boolean
        from_p_level: number
        to_p_level: number
      } = propOr({ has_changed: false }, 'data', response)
      updateCourseFlashcardsCount()

      if (data.has_changed) {
        updatePlvlStatsManually(data.from_p_level, data.to_p_level)
      }
      fetchStudentDetails()
      onSuccessAnswer(data)
      isABookView &&
        updateFlashcardManually({
          ...flashcard,
          proficiency_level: isCorrect ? Number(proficiencyLevel) + 1 : 1
        })
      setFlipping(true)
      setIsFlipped(false)
    }
    const handleError = e => {
      console.error(e)
      setIsLoading(false)
    }

    answerFlashcard({ id: isABookView ? originalId : id, isCorrect })
      .then(handleSuccess)
      .catch(handleError)
  }

  const renderProficiencyLevel =
    isABookView && proficiencyLevel ? (
      <ProficiencyLevel data-testid='FlashcardAnswer-ProficiencyLevel'>
        {t('flashcards.study.proficiencyLevel')}
        <ProficiencyNumber>{proficiencyLevel}</ProficiencyNumber>
      </ProficiencyLevel>
    ) : null

  const renderFlashcardOrder =
    isABookView && proficiencyLevel ? (
      <FlashcardPage data-testid='FlashcardAnswer-FlashcardPage'>
        {t('flashcards.study.flashcard')}:&nbsp;
        <FlashcardNumber data-testid='FlashcardAnswer-FlashcardNumber'>
          {numberCurrent}/{numberTotal}
        </FlashcardNumber>
      </FlashcardPage>
    ) : null

  const flashcardFront = (
    <FlashcardContentContainer
      id='content-container-front'
      data-testid='FlashcardFront'
    >
      {isValidDeltaObject(questionDeltaObject) && (
        <FlashcardContentTextContainer
          id='content-text-front'
          hasImage={isNotNilOrEmpty(questionImage)}
        >
          <HtmlRenderer htmlString={questionHtml} />
        </FlashcardContentTextContainer>
      )}
      {isNotNilOrEmpty(questionImage) && (
        <FlashcardContentImageContainer
          id='content-image-front'
          textHeight={textFrontHeight}
        >
          <Image url={questionImage} />
        </FlashcardContentImageContainer>
      )}
    </FlashcardContentContainer>
  )

  const flashcardBack = (
    <FlashcardContentContainer
      id='content-container-back'
      data-testid='FlashcardBack'
    >
      {isValidDeltaObject(explanationDeltaObject) && (
        <FlashcardContentTextContainer
          id='content-text-back'
          hasImage={isNotNilOrEmpty(explanationImage)}
        >
          <HtmlRenderer htmlString={explanationHtml} />
        </FlashcardContentTextContainer>
      )}
      {isNotNilOrEmpty(explanationImage) && (
        <FlashcardContentImageContainer
          id='content-image-back'
          textHeight={textBackHeight}
        >
          <Image url={explanationImage} />
        </FlashcardContentImageContainer>
      )}
    </FlashcardContentContainer>
  )

  const availableBeforeContent =
    flashcardSideToDisplay === FLASHCARD_SIDE.front
      ? flashcardFront
      : flashcardBack
  const availableAfterContent =
    flashcardSideToDisplay === FLASHCARD_SIDE.front
      ? flashcardBack
      : flashcardFront

  const openPurchasePortal = () => {
    window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
  }

  const CardUnavailable = isFlashcard ? (
    <FlashcardCard size={size} data-testid='FlashcardCard'>
      <FlashcardPlaceholder data-testid='FlashcardPlaceholder'>
        <SaltyImage src='/assets/illustrations/SaltyDexter.svg' />
        <SubscribeBtn data-testid='SubscribeBtn' onClick={openPurchasePortal}>
          {t('flashcards.freeTrialScaled')}
        </SubscribeBtn>
      </FlashcardPlaceholder>
    </FlashcardCard>
  ) : (
    <FlashcardCard data-testid='FlashcardCard' size={size}>
      {' '}
    </FlashcardCard>
  )

  const beforeContent = isAvailable ? availableBeforeContent : CardUnavailable

  const onNext = () => {
    setIsLoading(true)
    handleNext()
    afterNext && afterNext()

    if (book?.tag) {
      // @sieradz - flip flashcard in the book
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardSkipped, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
        'Flashcard Book tag': book?.tag || false,
        'Flashcard box tag': `${book?.tag}_${chapter.order}`,
        'Flashcard # id': flashcard?.id || false
      })
    } else {
      // @sieradz - flip flashcard on flashcard page
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.flashcardSkipped, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Flashcard Proficiency Level': flashcard?.proficiency_level || false,
        'Flashcard # id': flashcard?.id || false,
        Page: 'Flashcard page'
      })
    }
  }

  const onPrevious = () => {
    setIsLoading(true)
    handlePrevious()
  }

  const FlipCardBefore = (
    <div className='flip-container__before'>
      <FlashcardCard size={size} data-testid='FlashcardCardBefore'>
        {isLoading ? (
          <LoaderContainer data-testid='LoaderContainerBefore'>
            <BouncingLoader />
          </LoaderContainer>
        ) : (
          beforeContent
        )}
      </FlashcardCard>
      <FlashcardControls
        size={size}
        isABookView={isABookView}
        data-testid='FlashcardControls'
      >
        <div>
          {renderFlashcardOrder}
          <div className='side'>{beforeLabel}</div>
          {renderProficiencyLevel}
          <div className='controls'>
            {isABookView && (
              <Button
                data-testid='ButtonPrevious'
                size='small'
                color='secondary'
                onClick={onPrevious}
                disabled={disablePrevious || disableActions || isLoading}
                isLoading={isLoading}
                startIcon={<NavArrowLeftIcon />}
              >
                {t('flashcards.study.previous')}
              </Button>
            )}

            <Button
              data-testid='ButtonCorrect'
              size='small'
              color='secondary'
              onClick={handleFlip}
              startIcon={<ReloadIcon />}
              disabled={
                isNilOrEmpty(flashcard) ||
                disableActions ||
                !isAvailable ||
                isLoading
              }
              isLoading={isLoading}
            >
              {t('flashcards.study.flip')}
            </Button>
            <Button
              data-testid='ButtonIncorrect'
              size='small'
              color='secondary'
              onClick={onNext}
              disabled={
                isNilOrEmpty(flashcard) ||
                disableNext ||
                disableActions ||
                isLoading
              }
              isLoading={isLoading}
              endIcon={<NavArrowRightIcon />}
            >
              {isABookView
                ? t('flashcards.study.next')
                : t('flashcards.study.skip')}
            </Button>
          </div>
        </div>
      </FlashcardControls>
    </div>
  )

  const ButtonIncorrect = isPreviewAdmin ? (
    <Tooltip
      tooltipContent={t('flashcards.study.previewTooltip')}
      id='answer-incorrect-preview-tooltip'
    >
      <Button
        size='small'
        variant='contained'
        color='red'
        onClick={() => {}}
        startIcon={<CloseContainedIcon />}
        disabled
        data-testid='ButtonIncorrectPreview'
      >
        {t('flashcards.study.incorrect')}
      </Button>
    </Tooltip>
  ) : (
    <Button
      data-testid='ButtonFlip'
      size='small'
      variant='contained'
      color='red'
      startIcon={<CloseContainedIcon />}
      onClick={handleAnswerFlashcard(false)}
      disabled={
        !isAvailable || isLoading || disableAnswer || isFlashcardStateLoading
      }
      isLoading={isLoading}
    >
      {t('flashcards.study.incorrect')}
    </Button>
  )

  const ButtonCorrect = isPreviewAdmin ? (
    <Tooltip
      tooltipContent={t('flashcards.study.previewTooltip')}
      id='answer-correct-preview-tooltip'
    >
      <Button
        data-testid='ButtonCorrectPreview'
        size='small'
        variant='contained'
        color='green'
        onClick={() => {}}
        startIcon={<CheckmarkContainedIcon />}
        disabled
      >
        {t('flashcards.study.correct')}
      </Button>
    </Tooltip>
  ) : (
    <Button
      data-testid='ButtonCorrect'
      size='small'
      variant='contained'
      color='green'
      startIcon={<CheckmarkContainedIcon />}
      onClick={handleAnswerFlashcard(true)}
      disabled={
        !isAvailable || isLoading || disableAnswer || isFlashcardStateLoading
      }
      isLoading={isLoading}
    >
      {t('flashcards.study.correct')}
    </Button>
  )

  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  const handleOpenArchiveModal = () => setModalArchiveOpen(true)
  const handleCloseArchiveModal = () => setModalArchiveOpen(false)

  const afterContent = isAvailable ? availableAfterContent : CardUnavailable
  const [modalAddToCustomBoxOpen, setModalAddToCustomBoxOpen] = useState(false)

  const handleOpenAddToCustomBox = () => setModalAddToCustomBoxOpen(true)
  const handleCloseAddToCustomBox = () => setModalAddToCustomBoxOpen(false)

  const FlipCardAfter = (
    <div className='flip-container__after'>
      <FlashcardCard data-testid='FlashcardCardAfter' size={size}>
        {isLoading ? (
          <LoaderContainer data-testid='LoaderContainerAfter'>
            <BouncingLoader />
          </LoaderContainer>
        ) : (
          afterContent
        )}
      </FlashcardCard>
      <FlashcardControls size={size} isABookView={isABookView}>
        <div>
          {renderFlashcardOrder}
          <div className='side'>{afterLabel}</div>
          {renderProficiencyLevel}
          <div className='controls'>
            {!isABookView && (
              <ButtonBox data-testid='ButtonBoxAdd'>
                <Button
                  size='small'
                  color='primary'
                  disabled={isLoading}
                  startIcon={<AddToBoxIcon />}
                  onClick={handleOpenAddToCustomBox}
                  data-testid='ButtonAddToCustomBox'
                >
                  {t('flashcards.study.add')}
                </Button>
                <ModalAddToCustomBox
                  handleClose={handleCloseAddToCustomBox}
                  open={modalAddToCustomBoxOpen}
                  flashcard={flashcard}
                />
                <p>{t('flashcards.study.controlsDesc.add')}</p>
              </ButtonBox>
            )}
            <ButtonBox data-testid='ButtonBoxIncorrect'>
              {ButtonIncorrect}
              <p>{t('flashcards.study.controlsDesc.incorrect')}</p>
            </ButtonBox>
            {isABookView && (
              <Button
                data-testid='ButtonPrevious'
                size='small'
                color='secondary'
                onClick={onPrevious}
                disabled={disablePrevious || disableActions || isLoading}
                isLoading={isLoading}
                startIcon={<NavArrowLeftIcon />}
              >
                {t('flashcards.study.previous')}
              </Button>
            )}
            <ButtonBox data-testid='ButtonBoxFlip'>
              <Button
                data-testid='ButtonFlip'
                size='small'
                color='secondary'
                onClick={handleFlip}
                startIcon={<ReloadIcon />}
                disabled={
                  isNilOrEmpty(flashcard) || disableActions || isLoading
                }
                isLoading={isLoading}
              >
                {t('flashcards.study.flip')}
              </Button>
              <p>{t('flashcards.study.controlsDesc.flip')}</p>
            </ButtonBox>
            <ButtonBox data-testid='ButtonBoxNext'>
              <Button
                data-testid='ButtonNext'
                size='small'
                color='secondary'
                onClick={onNext}
                disabled={disableNext || disableActions || isLoading}
                isLoading={isLoading}
                endIcon={<NavArrowRightIcon />}
              >
                {isABookView
                  ? t('flashcards.study.next')
                  : t('flashcards.study.skip')}
              </Button>
              <p>{t('flashcards.study.controlsDesc.skip')}</p>
            </ButtonBox>
            <ButtonBox data-testid='ButtonBoxCorrect'>
              {ButtonCorrect}
              <p>{t('flashcards.study.controlsDesc.correct')}</p>
            </ButtonBox>
            {!isABookView && (
              <ButtonBox data-testid='ButtonBoxArchive'>
                <Button
                  size='small'
                  color='darkRed'
                  disabled={isLoading}
                  startIcon={<AddToArchiveIcon />}
                  onClick={handleOpenArchiveModal}
                >
                  {t('flashcards.study.archive')}
                </Button>
                <p>{t('flashcards.study.controlsDesc.archive')}</p>
              </ButtonBox>
            )}
          </div>
        </div>
      </FlashcardControls>
      <ModalAddCardToArchive
        handleClose={handleCloseArchiveModal}
        open={modalArchiveOpen}
        flashcardId={studentBookContentFlashcardId}
        proficiencyLevel={proficiencyLevel}
        user={user}
        flashcard={flashcard}
        currentCourse={currentCourse}
        flashcardBoxTag={`${flashcardBookTag}_${flashcard?.chapter_order}`}
        data-testid='ModalAddCardToArchive'
      />
    </div>
  )

  const addHotkeys = useCallback(
    event => {
      const elem = document.querySelector(':focus')
      // @ts-ignore
      elem && elem.blur()
      const key = event.key
      switch (true) {
        case isABookView && key === 'ArrowLeft' && !disablePrevious:
          handlePrevious()
          break
        case key === 'ArrowRight' && !disableNext:
          handleNext()
          afterNext && afterNext()
          break
        case key === 'ArrowUp' &&
          isFlipped &&
          isAvailable &&
          !disableAnswer &&
          !isFlashcardStateLoading:
          handleAnswerFlashcard(true)()
          break
        case key === 'ArrowDown' &&
          isFlipped &&
          isAvailable &&
          !disableAnswer &&
          !isFlashcardStateLoading:
          handleAnswerFlashcard(false)()
          break
        case key === ' ' && isAvailable && !isFlashcardStateLoading:
          handleFlip()
          break
        default:
          break
      }
    },
    [isFlipped, flashcard]
  )

  const [modalUsernameOpen, setModalUsernameOpen] = useState(false)

  const handleModalEvent = () =>
    setModalUsernameOpen(modalUsernameOpen => !modalUsernameOpen)

  useEffect(() => {
    eventEmitter.on(globalEvents.modalEditUsernameOpened, handleModalEvent)
  }, [])

  const handleHotkeys = e => {
    if (!isPaused && !modalUsernameOpen && !modalAddToCustomBoxOpen) {
      addHotkeys(e)
    }
  }

  // @ts-ignore
  useEffect(() => {
    if (!isABookView) {
      window.addEventListener('keyup', handleHotkeys)
      return () => {
        window.removeEventListener('keyup', handleHotkeys)
      }
    }
  }, [
    addHotkeys,
    flashcard,
    availableBookTags,
    currentCourse,
    learningTimeDetails,
    user,
    isAuthFetched,
    modalAddToCustomBoxOpen,
    modalUsernameOpen,
    isPaused
  ])

  useEffect(() => {
    const body = document.querySelector('body')
    // @ts-ignore
    body.style.overflow = flipping ? 'hidden' : 'auto'
  }, [flipping])

  return (
    <FlipContainer
      flipping={flipping}
      size={size}
      isFlipped={isFlipped}
      data-testid='FlipContainer'
    >
      <div className='flip-container__inner'>
        {FlipCardBefore}
        {FlipCardAfter}
      </div>
      {trackFlashcardsLearningTime && (
        <FlashcardsLearningTime
          data-testid='FlashcardsLearningTime'
          isAvailable={isAvailable}
          flashcardId={studentBookContentFlashcardId}
        />
      )}
    </FlipContainer>
  )
}

export default FlashcardAnswer

// large
// width: 715px;
// height: 572px;

// small
// width: 500px;
// height: 400px;

const FlipContainer = styled.div`
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: ${({ size }) => (size === 'large' ? '715px' : '500px')};
  height: ${({ size }) => (size === 'large' ? '572px' : '400px')};
  perspective: ${({ flipping }) => (flipping ? '1000px;' : 'none')};

  .flip-container__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'none')};
  }

  .flip-container__before,
  .flip-container__after {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main};
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-container__before {
    z-index: ${({ isFlipped }) => (isFlipped ? '0' : '1')};
  }

  .flip-container__before {
    opacity: ${({ isFlipped }) => (isFlipped ? '0' : '1')};
  }

  .flip-container__after {
    transform: rotateY(180deg);
    z-index: ${({ isFlipped }) => (isFlipped ? '1' : '0')};
  }

  .flip-container__after {
    opacity: ${({ isFlipped }) => (isFlipped ? '1' : '0')};
  }
`

const FlashcardPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 16px;
`

const SaltyImage = styled.img`
  height: 217px;
`

const SubscribeBtn = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  text-decoration: underline;
  cursor: pointer;
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary600};
  }
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FlashcardCard = styled.div`
  height: ${({ size }) => (size === 'large' ? '429px' : '300px')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px;

  div[aria-label='animation'] {
    max-height: ${({ size }) => (size === 'large' ? '429px' : '300px')};
  }

  #flashcard-front-text,
  #flashcard-back-text {
    padding: 8px;
    font-size: 14px !important;
    width: 100%;
  }
`

const FlashcardControls = styled.div`
  position: relative;
  height: ${({ size }) => (size === 'large' ? '143px' : '100px')};
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: ${({ size }) => (size === 'large' ? '100px' : 'auto')};

    svg {
      font-size: 12px;
    }
  }

  .__react_component_tooltip {
    max-width: 90px;
    padding: 8px;
  }

  &::before {
    position: absolute;
    content: '';
    width: calc(100% - 16px);
    height: 1px;
    background: ${({ theme }) => theme.colors.main.grey300};
    margin: 0 8px;
    top: 0;
    left: 0;
  }

  .controls {
    display: flex;
    gap: ${({ isABookView }) => (isABookView ? '8px' : '16px')};
  }

  .side {
    font-size: ${({ size }) => (size === 'large' ? '18px' : '16px')};
    margin-bottom: ${({ size }) => (size === 'large' ? '24px' : '12px')};
    letter-spacing: -0.1px;
    color: ${({ theme }) => theme.colors.main.heading};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
`

const ProficiencyLevel = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
`

const FlashcardPage = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
`

const FlashcardNumber = styled.div`
  font-weight: 700;
  margin-left: 4px;
`

const ProficiencyNumber = styled.div`
  font-weight: 700;
  margin-left: 4px;
`

const FlashcardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 100%;
  /* padding: 0 20px; */
`

const FlashcardContentTextContainer = styled.div`
  display: flex;
  align-items: center;
  transform: scale(1, 1) !important;
  width: 100%;
  height: 100%;
  flex-basis: ${({ hasImage }) => (hasImage ? '0' : 'unset')};

  & > div {
    width: 100%;
  }
`

const FlashcardContentImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1, 1) !important;
  height: 100%;
  max-height: calc(
    100% - ${({ textHeight }) => (textHeight > 0 ? textHeight : 0)}px
  );

  .image-container {
    display: flex;
    height: 100%;
    flex-basis: 100%;
  }

  .image-animation-container {
    height: 100%;
    width: 100%;
    display: inline-flex;

    div[aria-label='animation'] {
      display: inline-flex;
      width: 100%;
      height: 100%;
      max-height: unset !important;
      justify-content: center;
      flex-basis: 100%;
    }

    svg {
      height: 100%;
      display: inline-block;
    }
  }

  .image-svg-container {
    max-height: 100%;
    height: 100%;
    flex-basis: 0;
  }

  .image-img-container {
    max-height: 100%;
  }

  .controls-container {
    display: none;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: ${({ theme }) => theme.dimensions.buttonSmallHeight};
  }

  &:hover .controls-container {
    display: flex;
    opacity: 1;
  }
`
const ButtonBox = styled.div`
  p {
    margin-top: 4px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: ${({ theme }) => theme.colors.main.grey600};
  }
`
