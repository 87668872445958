import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { BookProgressChart } from 'modules/dashboard/components/BookProgressChart'
import { McatChart } from 'modules/dashboard/components/McatChart'
import { FlashcardsChart } from 'modules/dashboard/components/FlashcardsChart'
import { ContentQuestionsChart } from 'modules/dashboard/components/ContentQuestionsChart'
import { SaltyBucksChart } from 'modules/dashboard/components/SaltyBucksChart'
import TooltipFreeTrial from 'modules/dashboard/components/TooltipFreeTrial'
import TooltipFlashCardsChart from 'modules/dashboard/components/TooltipFlashCardsChart'
import TooltipChart from 'modules/dashboard/components/TooltipChart'
import { useSelector } from 'react-redux'
import { hasOnlyTestBundledBooks } from '../modules/books/ducks/selectors'
import { Redirect } from 'react-router-dom'
import PATHS from '../utils/paths'

import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent } from 'modules/auth/ducks/selectors'
import { Course } from 'types'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import GettingStarted from 'modules/dashboard/components/GettingStarted'
import Calendar from 'modules/dashboard/components/Calendar'
import { CalendarContextProvider } from 'hooks/CalendarContext'
import { BouncingLoader } from 'examkrackers-components'
import SideNav from 'components/SideNav'

const Dashboard = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const onlyTestBundleBooks = useSelector(hasOnlyTestBundledBooks)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const user = useSelector(getStudent)
  const [seeDashboardPage, setSeeDashboardPage] = useState(true)
  const [isGettingStartedCompleted, setIsGettingStartedCompleted] =
    useState(false)

  useEffect(() => {
    if (isNotNilOrEmpty(currentCourse.id) && seeDashboardPage) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.seeDashboardPage, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
      setSeeDashboardPage(false)
    }
  }, [currentCourse, seeDashboardPage])

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000)
  }, [])

  useEffect(() => {
    if (user && user.is_getting_started_completed !== undefined) {
      setIsGettingStartedCompleted(user.is_getting_started_completed)
    }
  }, [user, isLoading])

  switch (true) {
    case isLoading:
      return (
        <LoaderContainer>
          <BouncingLoader />
        </LoaderContainer>
      )
    case onlyTestBundleBooks:
      return <Redirect to={PATHS.exams} />
    default:
      return (
        <>
          <CalendarContextProvider>
            <Helmet>
              <title>{t('pages.dashboard')}</title>
            </Helmet>
            <TooltipFreeTrial />
            <TooltipFlashCardsChart />
            <TooltipChart />
            <DashboardWrapper>
              <SideNav />
              <LeftSide>
                <TopLeft>
                  <div>
                    {isGettingStartedCompleted ? (
                      <BookProgressWrapper>
                        <BookProgressChart />
                      </BookProgressWrapper>
                    ) : (
                      <GettingStarted
                        setIsGettingStartedCompleted={
                          setIsGettingStartedCompleted
                        }
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                  <Calendar />
                </TopLeft>
                <div>
                  {isGettingStartedCompleted ? (
                    <ContentQuestionsChart
                      isGettingStartedCompleted={isGettingStartedCompleted}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px'
                      }}
                    >
                      <BookProgressWrapper>
                        <BookProgressChart />
                      </BookProgressWrapper>
                      <div>
                        <ContentQuestionsChart
                          isGettingStartedCompleted={isGettingStartedCompleted}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </LeftSide>
              <RightSide>
                <div>
                  <SaltyBucksChart />
                </div>
                <div>
                  <McatChart />
                </div>
                <div>
                  <FlashcardsChart />
                </div>
              </RightSide>
            </DashboardWrapper>
          </CalendarContextProvider>
        </>
      )
  }
}

export default Dashboard

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DashboardWrapper = styled.div`
  margin-top: 10px;
  display: flex;
`

const LeftSide = styled.div`
  margin-right: 10px;
  padding-left: 10px;
`

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 11px;
  gap: 10px;
`
const RightSide = styled.div``

const BookProgressWrapper = styled.div`
  //width: 412px;
  display: flex;
  justify-content: center;
  align-items: center;
`
